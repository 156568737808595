import React, { useState } from 'react';

import { Article } from '@/model/article';
import ArticleView from '@/components/articles/article-view';
import BulletList from '@/components/scrollers/bullet-list/bullet-list';
import BulletListTitle from '@/components/scrollers/bullet-list/bullet-list-title';
import { RouteObject } from 'react-router-dom';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BaseScroller, BaseScrollerState } from '@/widgets/widgets/scrollers/base-scroller';
import { WidgetContainerContext } from '@/widgets/widget-container/widget-container-context';

function BulletListMainView({
  section,
  includedSections,
}: {
  section: string[];
  includedSections: string[];
}) {
  const [article, setArticle] = useState<Article | undefined>();

  if (article)
    return (
      <div className={'h-100 '}>
        <ArticleView article={article} onClose={() => setArticle(undefined)} />
      </div>
    );

  return (
    <div className={'h-100 '}>
      <BulletList dark={true} section={section} onArticleClick={(a: Article) => setArticle(a)} />
    </div>
  );
}

export class BulletListWidget extends BaseScroller {
  constructor(id: string, section: string[], initialState: BaseScrollerState, _shortTitle: string) {
    super(`/bullets/${id}`, section, initialState, _shortTitle);
  }

  get headerPanel(): JSX.Element {
    return (
      <WidgetContainerContext.Consumer>
        {widgetContainer => (
          <>
            <FontAwesomeIcon icon={faList} />{' '}
            <BulletListTitle
              title={
                (widgetContainer.getState<BaseScrollerState>()?.title ?? this.defaultTitle) || ''
              }
              section={this.section}
            />
          </>
        )}
      </WidgetContainerContext.Consumer>
    );
  }

  headerActions({ children }: { children?: React.ReactNode }): JSX.Element {
    return <>{children}</>;
  }

  get routes(): RouteObject[] {
    return [
      {
        path: `/`,
        element: (
          <WidgetContainerContext.Consumer>
            {widgetContainer => {
              const includedSections =
                widgetContainer.getState<BaseScrollerState>()?.includedSections;
              return <BulletListMainView section={this.section} includedSections={this.section} />;
            }}
          </WidgetContainerContext.Consumer>
        ),
      },
    ];
  }
  get defaultRoute(): string {
    return `/`;
  }
}
