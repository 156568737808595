import React from 'react';
import ConnectionWarning from '@/components/shared/connection-warning';
import ConnectionIndicator from '@/components/shared/connection-indicator';
import ReaderIndicator from '@/components/shared/reader-indicator';

export default function ConnectionIndicators({ children }: React.PropsWithChildren<{}>) {
  return (
    <>
      <ConnectionWarning />
      {children}
      <div
        className={'bg-black p-1'}
        id={'connectionIndicator'}
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          zIndex: 100,
        }}
      >
        <ConnectionIndicator />
      </div>
      <div
        className={'bg-black p-1 text-base-light'}
        id={'readerIndicator'}
        style={{
          position: 'fixed',
          bottom: 0,
          right: 0,
          zIndex: 100,
        }}
      >
        <ReaderIndicator />
      </div>
    </>
  );
}
