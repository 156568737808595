import { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';

import DateFormat from '@/components/shared/date-format';
import useTimer from '@/hooks/use-timer';

export default function Clock() {
  const [date, setDate] = useState<Dayjs>(dayjs());

  useTimer(
    1000,
    () => {
      setDate(dayjs());
    },
    true,
  );

  return (
    <>
      {' '}
      <DateFormat format={'h:mm:ss a'} date={date} />
    </>
  );
}
