import { Dropdown } from 'react-bootstrap';

import './pinned-article.css';

const ColorPicker = ({
  selectedColor,
  handleColorSelect,
}: {
  selectedColor: string;
  handleColorSelect: (color: string) => void;
}) => {
  const availableColors = ['note-yellow', 'note-blue', 'note-green', 'note-orange'];
  return (
    <Dropdown style={{ display: 'inline', padding: '8px' }}>
      <Dropdown.Toggle
        variant="light"
        id="dropdown-basic"
        className={`${selectedColor}-picker`}
        style={{
          width: '18px',
          height: '18px',
          borderRadius: '50%',
          border: '2px solid #333',
          marginTop: '2px',
          padding: 0,
        }}
      ></Dropdown.Toggle>

      <Dropdown.Menu className="custom-dropdown-menu">
        {availableColors
          .filter(c => c !== selectedColor)
          .map((c, i) => (
            <Dropdown.Item
              key={`color-${i}`}
              style={{
                padding: '5px',
                width: '20px',
              }}
              onClick={() => handleColorSelect(c)}
            >
              <div
                className={`${c}-picker`}
                style={{
                  width: '20px',
                  height: '20px',
                  borderRadius: '50%',
                }}
              ></div>
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ColorPicker;
