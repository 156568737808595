import { CredentialsHeader } from './credentials-header';
import { CredentialsBody } from './credentials-body';
import { Token } from './token';

export interface CredentialsData {
  header: CredentialsHeader;
  body: CredentialsBody;
  refresh_expiration: Date;
  access_expiration: Date;
  token: Token;
}

export function createCredentials(credentials: CredentialsData): Credentials {
  const result = credentials as Credentials;

  result.refresh_expiration = new Date(credentials.refresh_expiration);
  result.access_expiration = new Date(credentials.access_expiration);
  result.accessTokenExpired = () => {
    return result.access_expiration.getTime() < new Date().getTime();
  };
  result.refreshTokenExpired = () => {
    return result.refresh_expiration.getTime() < new Date().getTime();
  };
  return result;
}

export interface Credentials extends CredentialsData {
  accessTokenExpired(): boolean;
  refreshTokenExpired(): boolean;
}
