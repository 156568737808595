import { NewsEvent } from '@/model/calendar/news-event';
import { CoverageBadge } from '@/components/data-calendar/coverage/coverage-badge';

export function CoverageBadges({ event }: { event: NewsEvent }) {
  return (
    <>
      <CoverageBadge
        event={event}
        coverageType={'PREVIEW'}
        className={'bg-tertiary m-1'}
        label={'Preview'}
      />
      <CoverageBadge
        event={event}
        coverageType={'RELATED'}
        className={'bg-tertiary-600 m-1'}
        label={'Coverage'}
      />
      <CoverageBadge
        event={event}
        coverageType={'REVIEW'}
        className={'bg-primary-500 m-1'}
        label={'Review'}
      />
    </>
  );
}
