export class FormattingUtil {
  private constructor() {}

  static formatNumber(
    input: string | null | number | undefined,
  ): string | null | number | undefined {
    if (input === null || input === undefined) return input;

    const floatValue = parseFloat(`${input}`);

    if (isNaN(floatValue)) return input;

    return (floatValue + Number.EPSILON).toLocaleString('en-US', {
      maximumFractionDigits: 3,
      minimumFractionDigits: 1,
    });
  }
}
