import dayjs from 'dayjs';

import Calendar from '@/components/data-calendar/calendar';
import useWidgetParams from '@/widgets/widget-router/use-widget-params';
import useWidgetNavigate from '@/widgets/widget-router/use-widget-navigate';

export default function DataCalendarPage() {
  let { date, view } = useWidgetParams();

  const navigate = useWidgetNavigate();

  return (
    <Calendar
      key={'calendar-' + date + '-' + view}
      date={date || 'today'}
      view={(view || 'daily') as 'daily' | 'weekly'}
      onNavigate={({ date, view }) => {
        console.log('Navigating', date, view);
        navigate(`/${view}/${date}`);
      }}
      onEventSelect={({ date, event }) => {
        if (event.auction) {
          navigate(
            `/auction-event/${dayjs(event.date).valueOf()}/${
              event.auction.auctionProducts[0].auctionHolder.id
            }`,
          );
        } else navigate(`/event/${event.id}`);
      }}
    />
  );
}
