import { useEffect, useState } from 'react';
import { ClockLoader } from 'react-spinners';
import dayjs from 'dayjs';
import { NewsEvent } from '@/model/calendar/news-event';
import PageControl from '@/components/shared/page-control';
import CountryFlag from '@/components/shared/country-flag';
import DateFormat from '@/components/shared/date-format';
import AddToCalendarButton from '@/components/shared/add-to-calendar-button';
import { CoverageBadges } from '@/components/data-calendar/coverage/coverage-badges';

import styles from '@/components/hot-list/hot-list.module.scss';
import theme from '@/index.module.scss';
import { selectApi } from '@/api';

export default function HotList({ eventClick }: { eventClick: (event: NewsEvent) => void }) {
  const size = 15;

  const cutoff = dayjs().add(6, 'month').valueOf();

  const [loading, setLoading] = useState<boolean>(false);
  const [events, setEvents] = useState<NewsEvent[]>([]);
  const [eventsPage, setEventsPage] = useState<NewsEvent[]>([]);
  const [offset, setOffset] = useState<number>(0);
  const [currentEnd, setCurrentEnd] = useState<number>(new Date().getTime());

  const filter = (value: NewsEvent) => value.rating >= 3;

  const getEventsPage = async (page: number) => {
    let end = currentEnd;

    while (events.length < (page + 1) * size && end < cutoff) {
      let newEnd = dayjs(end).add(1, 'week').valueOf();

      let page: NewsEvent[] = await selectApi.getCalendar(end, newEnd);

      page.filter(filter).forEach(value => {
        if (events.filter(e => e.id === value.id).length === 0) events.push(value);
      });

      end = newEnd;
    }

    setCurrentEnd(end);
    setEvents(events);
    setEventsPage(events.slice(size * page, size * (page + 1)));
  };

  let fetch = async (offset: number) => {
    if (loading) return;

    setLoading(true);
    await getEventsPage(offset);
    setLoading(false);
  };

  useEffect(() => {
    fetch(offset).catch(reason => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setPage = (o: number) => {
    setOffset(o);
    fetch(o).catch(reason => {});
  };

  const dateClass = (d: string) => {
    if (dayjs(d).isBefore(dayjs().add(1, 'day'))) return 'text-error';
    if (dayjs(d).isBefore(dayjs().add(2, 'day'))) return 'text-tertiary';
    if (dayjs(d).isAfter(dayjs().add(14, 'day'))) return 'text-text-light';

    return 'text-success';
  };

  return (
    <div className="container-element">
      <div className={' h-100 d-flex flex-column container-responsive-text'}>
        <div className={''}>
          <PageControl
            offset={offset}
            onChange={setPage}
            title={<strong>🔥 Upcoming Hot Events</strong>}
          />

          {loading && (
            <div className={'p-5 m-5 d-flex justify-content-around flex-row'}>
              <ClockLoader color={theme.info} size={70} />
            </div>
          )}
        </div>
        <div className={'overflow-auto'}>
          {loading || (
            <table className={'table table-sm text-text-dark '} key={offset}>
              <thead>
                <tr className={'bg-base-dark'}>
                  <th>Date</th>
                  <th></th>
                  <th>Event</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {eventsPage.map(value => (
                  <tr
                    key={'event-' + value.id}
                    className={`${styles.hotEvent} `}
                    onClick={() => eventClick(value)}
                  >
                    <td>
                      <DateFormat format={'M/DD h:mm a'} date={value.date}></DateFormat>
                    </td>
                    <td>
                      <span className={dateClass(value.date)}>{dayjs(value.date).fromNow()}</span>
                    </td>
                    <td>
                      {' '}
                      <CountryFlag country={value.country} width={20} height={12} /> {value.title}
                    </td>
                    <td>
                      <CoverageBadges event={value} />
                    </td>
                    <td onClick={event => event.stopPropagation()}>
                      <AddToCalendarButton event={value} style={{ margin: 0, padding: 0 }} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
}
