import { NewsWidgetFactory, WidgetMenuItem } from '@/widgets/news-widget-factory';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbtack } from '@fortawesome/free-solid-svg-icons';
import { routeMatches } from '@/widgets/widget-router/route-matches';
import { NewsWidget } from '@/widgets/news-widget';
import { PinnedArticleWidget } from '@/widgets/widgets/pinned/pinned-article-widget';

export class PinnedArticleWidgetFactory implements NewsWidgetFactory {
  get path(): string {
    return '/pinned/:articleId';
  }

  get headerPanel(): JSX.Element {
    return (
      <>
        <FontAwesomeIcon icon={faThumbtack} /> Pinned Articles
      </>
    );
  }

  matchesTemplate(s: string): boolean {
    return !!routeMatches(this.path, s);
  }

  createInstance(s: string): NewsWidget {
    const match = routeMatches(this.path, s);

    if (match) return new PinnedArticleWidget(match.articleId);

    throw new Error('invalid url');
  }

  getListableUrls(): WidgetMenuItem[] {
    return [];
  }
}
