import { ClientSubscription } from '@/services/messaging/client-subscription';
import { Client } from '@stomp/stompjs';
import { Subscription } from '@/services/messaging/subscription';
import { authService, localPreferencesService } from '@/services/index';
import SockJS from 'sockjs-client';
import { ConnectionState } from '@/services/messaging/connection-state';
import { MessageServiceConstants } from '@/services/messaging/message-service-constants';
import { MessageConnectionType } from '@/services/messaging/message-connection-type';

let client: Client;

let subscriptions: Subscription[] = [];

let hasError: boolean = false;

let _lastSuccessfulConnectionTime: Date = new Date();

let messageConnectionType: MessageConnectionType = MessageConnectionType.WEBSOCKET;
let brokerURL: string = process.env.REACT_APP_SELECT_WS_HOST as string;
let fallbackURL: string = process.env.REACT_APP_SELECT_FALLBACK_HOST as string;

client = getClient();

localPreferencesService.getLocalPreferences().then(value => {
  if (value.messageConnectionType) {
    setConnectionType(value.messageConnectionType);
    client = getClient();
  }

  activateConnection();
});

function lastSuccessfulConnectionTime(): Date {
  if (client.connected) {
    _lastSuccessfulConnectionTime = new Date();
  }

  return _lastSuccessfulConnectionTime;
}

function getClient() {
  const reconnectDelay = 5000;

  console.log('Creating connection', messageConnectionType);

  if (messageConnectionType === MessageConnectionType.WEBSOCKET) {
    console.log('Creating websocket connection');
    return new Client({
      brokerURL: brokerURL,
      reconnectDelay,
      debug: msg => {
        // console.debug(msg);
      },
    });
  } else {
    console.log('Creating HTTP connection');

    const transport = ['xhr-polling'];

    if (messageConnectionType === MessageConnectionType.STREAMING) {
      transport.push('xhr-streaming');
    }

    return new Client({
      webSocketFactory: () => {
        return new SockJS(
          fallbackURL,
          {},
          {
            transports: transport,
            sessionId: () => {
              return '' + new Date().getTime();
            },
          },
        );
      },
      reconnectDelay,
    });
  }
}

function activateConnection() {
  authService.onTokenAvailable().then(
    value => {
      client.connectHeaders = {
        passcode: value.access_token,
      };

      client.beforeConnect = async () => {
        console.log('Updating STOMP access token');
        client.connectHeaders = {
          passcode: (await authService.getAccessToken())?.access_token as string,
        };
      };

      client.heartbeatIncoming = 30000;
      client.heartbeatOutgoing = 0;

      client.onConnect = receipt => {
        _lastSuccessfulConnectionTime = new Date();
        subscriptions.forEach(sub => activateSubscription(sub));
        hasError = false;
        publish<ConnectionState>(MessageServiceConstants.MESSAGE_CONNECTION_STATE, {
          connected: true,
        });
      };

      client.onDisconnect = frame => {
        console.debug('DISONNECTED');
        publish<ConnectionState>(MessageServiceConstants.MESSAGE_CONNECTION_STATE, {
          connected: false,
        });
      };

      client.onStompError = frame => {
        hasError = true;
        client.forceDisconnect();
        publish<ConnectionState>(MessageServiceConstants.MESSAGE_CONNECTION_STATE, {
          connected: false,
        });
      };

      client.activate();
    },
    reason => {
      console.error('COULD NOT ACTIVATE CONNECTION');
    },
  );
}

function getConnectionType(): MessageConnectionType {
  return messageConnectionType;
}

function setConnectionType(type: MessageConnectionType) {
  console.log('Setting connection type', type);
  messageConnectionType = type;
}

async function reactivateConnection(): Promise<void> {
  await client.deactivate({ force: true });

  publish<ConnectionState>(MessageServiceConstants.MESSAGE_CONNECTION_STATE, {
    connected: false,
  });
  client = getClient();
  activateConnection();
}

function isConnected(): boolean {
  return client.connected && !hasError;
}

function subscribe<T>(
  topic: string,
  callback: (message: T, headers: { [key: string]: string }) => void,
): ClientSubscription {
  let subscription = subscriptions.find(value => value.destination === topic);

  if (!subscription) {
    subscription = new Subscription(topic);

    if (client.connected) {
      activateSubscription(subscription);
    }

    subscriptions.push(subscription);
  }

  try {
    return new ClientSubscription(callback, subscription);
  } finally {
    dumpState();
  }
}

function dumpState() {
  // console.log(
  //   subscriptions.map(value => {
  //     return { topic: value.destination, count: value.clientSubscriptions.length };
  //   }),
  // );
}

function activateSubscription(subscription: Subscription) {
  if (subscription.isLocal) {
    // console.log('Local subscription, no activation', subscription);
  } else {
    // console.log('Activating remote subscription', subscription);

    subscription.stompSubscription = client.subscribe(subscription.destination, message => {
      // console.log('Received remote message', message);
      const body = JSON.parse(message.body);
      subscription.publish(body, message.headers);
    });
  }
}

function publish<T>(topic: string, message: T) {
  subscriptions.forEach(value => {
    if (value.destination === topic) {
      value.publish(message, {});
    }
  });
}

export default {
  publish,
  subscribe,
  lastSuccessfulConnectionTime,
  isConnected,
  getConnectionType,
  setConnectionType,
  reactivateConnection,
};
