import React, { useContext } from 'react';
import { RouteObject } from 'react-router-dom';

import { articleApi } from '@/api';
import { NewsWidget } from '@/widgets/news-widget';
import { WidgetHost } from '@/widgets/widget-host';
import { WidgetContainerContext } from '@/widgets/widget-container/widget-container-context';
import { WidgetContainer } from '@/widgets/widget-container';
import {analyticsService} from '@/services';
import {AnalyticsEvent} from '@/services/analytics/analytics-event';
import WidgetLink from '@/widgets/widget-router/widget-link';
import PinnedArticlePage from '@/pages/news/pinned-article-page';
import { qCode } from '@/model/concept-item';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNewspaper, faThumbTackSlash } from '@fortawesome/free-solid-svg-icons';
import ColorPicker from '@/components/pins/pinned-color-select';
import PinnedSeriesList from '@/components/pins/pinned-series-list';

export interface PinnedWidgetState {
  title?: string;
}

export interface PinnedWidgetPreferences {
  color?: string;
}

function PinnedSeriesWidgetHeader({ children }: React.PropsWithChildren<{}>) {
  const widgetContainer = useContext<WidgetContainer>(WidgetContainerContext);

  const selectColor = (s: string) => {
    analyticsService.recordAnalyticsEvent(AnalyticsEvent.PINNED_ARTICLE_COLOR_CHANGE, {
      color:s
    })
    widgetContainer.updatePreferences<PinnedWidgetPreferences>({
      color: s,
    });
  };

  return (
    <>
      <button className={'btn btn-link btn-sm'} onClick={widgetContainer.close}>
        <FontAwesomeIcon icon={faThumbTackSlash} />
      </button>
      {children}
      <ColorPicker
        selectedColor={
          widgetContainer.getPreferences<PinnedWidgetPreferences>()?.color ?? 'note-yellow'
        }
        handleColorSelect={selectColor}
      />
    </>
  );
}

export class PinnedSeriesWidget implements NewsWidget<PinnedWidgetState, PinnedWidgetPreferences> {
  constructor(private genre: string) {}

  get defaultRoute(): string {
    return '';
  }

  defaultTitle: string = 'Pinned Series';

  async hasPermission() {
    return true;
  }

  get headerPanel(): JSX.Element {
    return (
      <WidgetContainerContext.Consumer>
        {widgetContainer => (
          <>
            <span className={'w-100   position-relative'}>
              <FontAwesomeIcon icon={faNewspaper} />{' '}
              {widgetContainer.getState<PinnedWidgetState>()?.title ?? this.defaultTitle}
            </span>
            <span
              className={'position-absolute  w-100 text-center '}
              style={{ top: 0, left: 0, zIndex: 100, pointerEvents: 'none' }}
            >
              <img src={'/pin.svg'} width={'50px'} alt={'pin'} />
            </span>
          </>
        )}
      </WidgetContainerContext.Consumer>
    );
  }

  headerActions({ children }: { children?: React.ReactNode }): JSX.Element {
    return <PinnedSeriesWidgetHeader children={children} />;
  }

  get id(): string {
    return '/pinned-series/' + this.genre;
  }

  initialize(host: WidgetHost): void {}
  containerClass(state?: PinnedWidgetState, preferences?: PinnedWidgetPreferences) {
    return `${preferences?.color ?? 'note-yellow'} sticky-note shadow-sm`;
  }

  get routes(): RouteObject[] {
    return [
      {
        path: '/',
        element: (
          <WidgetContainerContext.Consumer>
            {widgetContainer => (
              <PinnedArticlePage
                className={
                  widgetContainer.getPreferences<PinnedWidgetPreferences>()?.color ?? 'note-yellow'
                }
                widgetLink={<WidgetLink to="/list" className='fs-5'>History</WidgetLink>}
              />
            )}
          </WidgetContainerContext.Consumer>
        ),
        loader: async ({}) => {
          try {
            const articlePage = await articleApi.getArticlesBySection(undefined, {
              limit: 1,
              includeGenres: [this.genre],
            });

            if (articlePage.content.length < 1) {
              return {
                article: undefined,
                title: this.defaultTitle,
                genre: this.genre,
                loaded: true,
              };
            }

            const article = articlePage.content[0];

            return {
              article,
              title: article.genre.find(value => qCode(value) === this.genre)?.name,
              genre: this.genre,
              loaded: true,
            };
          } catch (e) {
            return {
              article: undefined,
              title: this.defaultTitle,
              genre: this.genre,
              loaded: true,
            };
          }
        },
      },
      {
        path: '/list',
        element: <PinnedSeriesList series={[this.genre]} />,
      },
      {
        path: '/list/:id',
        element: (
          <WidgetContainerContext.Consumer>
            {widgetContainer => (
              <PinnedArticlePage
                className={
                  widgetContainer.getPreferences<PinnedWidgetPreferences>()?.color ?? 'note-yellow'
                }
                widgetLink={
                  <div className="d-flex justify-content-between">
                    <WidgetLink to="/list" className='fs-5'>Back to list</WidgetLink>
                    <WidgetLink to="/" className='fs-5'>Show latest version</WidgetLink>
                  </div>
                }
              />
            )}
          </WidgetContainerContext.Consumer>
        ),
        loader: async ({ params }) => {
          try {
            const articlePage = await articleApi.getArticle(params.id || '');

            const article = articlePage;

            return {
              article,
              title: article.genre.find(value => qCode(value) === this.genre)?.name,
              genre: this.genre,
              loaded: true,
            };
          } catch (e) {
            return {
              article: undefined,
              title: this.defaultTitle,
              genre: this.genre,
              loaded: true,
            };
          }
        },
      },
    ];
  }

  get shortTitle(): JSX.Element {
    return <>Test</>;
  }
}
