import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Article } from '@/model/article';
import { SearchResult } from '@/model/news/search-result';
import KeywordBadge from '@/components/shared/keyword-badge';
import Menu from '@/components/shared/menu';
import SearchResultList from '@/components/search/search-result-list';
import { articleApi } from '@/api';
import PageControl from '@/components/shared/page-control';
import { GridLoader } from 'react-spinners';
import Loading from '@/components/shared/loading';
import {analyticsService} from '@/services';
import {AnalyticsEvent} from '@/services/analytics/analytics-event';

function SearchTerm({ term }: { term: string }) {
  let c = 'text-secondary';

  if (term.startsWith('+')) c = 'text-success-light';
  if (term.startsWith('-')) c = 'text-danger-light';

  return <code className={c}>{term} </code>;
}

export default function SearchPanel() {
  const [query, setQuery] = useState<string>('');

  const [searchTerms, setSearchTerms] = useState<string[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [lastQuery, setLastQuery] = useState<string>('');
  const [articles, setArticles] = useState<SearchResult<Article>[]>();
  const [sort, setSort] = useState<'score' | 'firstcreated'>('firstcreated');
  const [page, setPage] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const queryChange = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const search = (searchTerms: string[], tags: string[], page: number) => {
    setLoading(true);
    setArticles(undefined);
    articleApi
      .search(searchTerms.join(' '), { sort, keywords: tags, page: page })
      .then(value => {
        setLastQuery(query);
        setArticles(value.content);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const doSearch = (event?: FormEvent<HTMLFormElement>) => {
    event && event.preventDefault();

    if (!query) return;

    analyticsService.recordAnalyticsEvent(AnalyticsEvent.SEARCH_ENTERED, {
      query: query
    })

    const searchTerms: string[] = [];
    const tags: string[] = [];

    query.split(/\s+/).forEach(value => {
      if (value.startsWith('#')) tags.push(value.replaceAll('#', ''));
      else searchTerms.push(value);
    });

    setSearchTerms(searchTerms);
    setTags(tags);
    setPage(0);
    if (query.trim() === '') {
      setArticles(undefined);
    } else {
      search(searchTerms, tags, 0);
    }
  };

  const onChangeValue = (event: any) => {
    setSort(event.target.value);
  };

  useEffect(() => {
    doSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort]);

  useEffect(() => {
    if (query.trim() === '') {
      setArticles(undefined);
    } else {
      search(searchTerms, tags, page);
    }
  }, [page]);

  return (
    <div className="container-element h-100">
      <div className={'h-100 d-flex flex-column container-responsive-text text-lg-start'}>
        <div>
          <form onSubmit={event => doSearch(event)}>
            <div className="input-group input-group-sm mb-2">
              <input
                type="text"
                className="form-control"
                placeholder="enter search terms and #tags"
                aria-label="enter search terms"
                aria-describedby="button-addon2"
                onChange={event => queryChange(event)}
              />
              <input
                type={'submit'}
                className="btn btn-sm btn-outline-secondary"
                id="button-addon2"
                value={'Search'}
              />
              <Menu />
            </div>
            <div>
              Sort: &nbsp;
              <input
                className="form-check-input"
                type={'radio'}
                name={'sort'}
                value={'score'}
                onChange={event => onChangeValue(event)}
                checked={sort === 'score'}
              />{' '}
              Score &nbsp;
              <input
                className="form-check-input"
                type={'radio'}
                name={'sort'}
                value={'firstcreated'}
                onChange={event => onChangeValue(event)}
                checked={sort === 'firstcreated'}
              />{' '}
              Date
            </div>
          </form>

          <p>
            Search results for:{' '}
            {searchTerms.map(value => (
              <SearchTerm term={value} key={'term-' + value} />
            ))}{' '}
            with tags:{' '}
            {tags.map(s => (
              <span className={'small'}>
                <KeywordBadge keyword={s} />{' '}
              </span>
            ))}{' '}
            (sorting on {sort})
          </p>
        </div>
        {articles && articles.length > 0 && (
          <div className={'mb-3'}>
            <PageControl offset={page} onChange={setPage} />
          </div>
        )}

        <div className={'flex-fill  overflow-auto'}>
          {loading && (
            <div>
              <Loading />
            </div>
          )}
          {articles && articles.length > 0 && (
            <div>
              <SearchResultList onArticleClick={() => {}} articles={articles} />
            </div>
          )}
          {!loading && articles && articles.length === 0 && (
            <div className={'text-muted h4 text-center p-5'}>Nothing found for: {lastQuery}</div>
          )}
          {!loading && !articles && (
            <div className={'text-muted h4 text-center p-5'}>Enter search terms above</div>
          )}
        </div>
      </div>
    </div>
  );
}
