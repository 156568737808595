import { StorageService } from '@/services/storage-service';
import { LocalStorageUtil } from '@/services/util/local-storage-util';

const localStorage = new LocalStorageUtil();

export class LocalStorageService implements StorageService {
  async getValue<T>(key: string): Promise<T> {
    return Promise.resolve(localStorage.getValue<T>(key) as T);
  }

  async saveValue(key: string, t: any): Promise<void> {
    localStorage.saveValue(key, t);
    return Promise.resolve();
  }

  async removeValue(key: string): Promise<void> {
    localStorage.removeValue(key);
    return Promise.resolve();
  }
}
