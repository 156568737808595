import { NewsWidget } from '@/widgets/news-widget';
import { RouteObject } from 'react-router-dom';
import { WidgetHost } from '@/widgets/widget-host';
import Clock from '@/components/clock/clock';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';

export class ClockWidget implements NewsWidget {
  get defaultRoute(): string {
    return '/';
  }

  get defaultTitle(): string {
    return 'World Clock';
  }

  hasPermission(permissions: string[]): Promise<boolean> {
    return Promise.resolve(true);
  }

  headerActions({ children }: { children?: React.ReactNode }): JSX.Element {
    return <>{children}</>;
  }

  get headerPanel(): JSX.Element {
    return (
      <>
        <FontAwesomeIcon icon={faClock} /> World Clock
      </>
    );
  }

  get id(): string {
    return 'clock';
  }

  initialize(host: WidgetHost): void {}

  get routes(): RouteObject[] {
    return [
      {
        path: '/',
        element: <Clock />,
      },
    ];
  }

  get shortTitle(): JSX.Element {
    return <>Clock</>;
  }
}
