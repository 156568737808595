import React from 'react';

import { InstantAnswers } from '@/model/calendar/instant-answers';

export default function InstantAnswersRowEntry({
  instantAnswers,
}: {
  instantAnswers: InstantAnswers;
}) {
  const iaValue = () => {
    switch (instantAnswers.state) {
      case 'AWAITING_DEFINITION':
        return (
          <td colSpan={4} className={'text-tertiary text-center border-start border-end'}>
            Awaiting Definition
          </td>
        );
      case 'NOT_AUTHORIZED':
        return (
          <td colSpan={4} className={'text-muted text-center border-start border-end'}>
            <span className={'badge bg-warning-light text-dark'}>Try MNI Instant Answers!</span>
          </td>
        );
      default:
        return (
          <td colSpan={4} className={'text-muted text-center border-start border-end'}>
            {instantAnswers.questions.length} Q's
            {instantAnswers.state === 'PUBLISHED' ? (
              <span className={'text-success-light'}> Received </span>
            ) : (
              ''
            )}
          </td>
        );
    }
  };

  return (
    <React.Fragment>
      <td colSpan={2} className={'text-text-dark'}>
        {instantAnswers.display}
      </td>

      {iaValue()}
    </React.Fragment>
  );
}
