import React, { useEffect, useState } from 'react';

import styles from '@/components/shared/test-env-indicator/index.module.css';

const testEnv = process.env.REACT_APP_TEST_ENV === 'true';
export default function TestEnvIndicator({ children }: React.PropsWithChildren<any>) {
  const [flash, setFlash] = useState<boolean>(true);

  useEffect(() => {
    const timer = setInterval(() => {
      setFlash(!flash);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  });

  if (testEnv) {
    return (
      <div className={styles.animatedBorder}>
        <div className={'container'}>
          <div className={'row sticky-top text-center opacity-0'}>
            <div className={'col'}>
              <div className={'alert alert-warning m-2'}> T</div>
            </div>
          </div>
          <div className={'row fixed-top text-center'}>
            <div className={'col'}>
              <div className={'alert alert-warning m-2'}>
                {flash && '⚠️'} TEST ENVIRONMENT {flash && '⚠️'}
              </div>
            </div>
          </div>
        </div>

        <div className="bg-base-light">{children}</div>
      </div>
    );
  } else {
    return <>{children}</>;
  }
}
