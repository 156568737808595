export default function RatingIndicator({ rating }: { rating: number }) {
  if (rating <= 1)
    return (
      <span style={{ fontSize: '0.9em' }} className={'text-secondary'}>
        ★
      </span>
    );
  else if (rating === 2)
    return (
      <span style={{ fontSize: '0.9em' }} className={'text-warning-light'}>
        ★★
      </span>
    );
  else if (rating >= 3)
    return (
      <span style={{ fontSize: '0.6em' }} className={'text-warning-light'}>
        🔥🔥🔥
      </span>
    );

  return <></>;
}
