export enum ConceptItemConstants {
  BELL = 'mni-section:480257799',
}

export function qCode(conceptItem: ConceptItem) {
  return `${conceptItem.scheme}:${conceptItem.code}`;
}

export interface ConceptItem {
  name: string;
  code: string;
  scheme: string;
  type: 'SUBJECT' | 'GENRE';
  attributes: { [key: string]: any };
}
