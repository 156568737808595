import { createContext, useContext, useState } from 'react';

type Calendar = {
  view: 'daily' | 'weekly';
  setView: (view: 'daily' | 'weekly') => void;
  date: string;
  setDate: (date: string) => void;
};

export const CalendarContext = createContext<Calendar | null>(null);

export default function CalendarProvider({ children }: { children: React.ReactNode }) {
  const [view, setView] = useState<'daily' | 'weekly'>('daily');
  const [date, setDate] = useState('today');

  const context: Calendar = {
    view,
    setView,
    date,
    setDate,
  };
  return <CalendarContext.Provider value={context}>{children}</CalendarContext.Provider>;
}

export function useCalendar() {
  const calendar = useContext(CalendarContext);
  if (calendar === null) {
    throw new Error('useCalendar hook must be used within CalendarProvider');
  }

  return calendar;
}
