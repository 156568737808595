import { Button, Modal } from 'react-bootstrap';
import { useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import './pdf-viewer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight, faCircleXmark } from '@fortawesome/free-solid-svg-icons';

let pdfClickHandler = (s: string) => {};
let imageClickHandler = (s: string, e: Callback) => {};

type Callback = () => void;
const listener = (cb: Callback) => {
  return (e: Event) => {
    let et = e.target as HTMLElement;
    let target = et.closest('a') as HTMLElement;
    let image = et.getElementsByTagName('img');
    if (target) {
      // if the click was on or within an <a>
      // then based on some condition...
      if (
        target.getAttribute('href')?.endsWith('.pdf') &&
        (target.getAttribute('href')?.includes('marketnews.com') ||
          target.getAttribute('href')?.includes('mnimarkets.com'))
      ) {
        e.preventDefault(); // tell the browser not to respond to the link click
        e.stopPropagation();
        // then maybe do something else
        pdfClickHandler(target.getAttribute('href') || '');
      }
    } else if (image.length > 0) {
      imageClickHandler(image[0].src, cb);
    }
  };
};

export function addClickHandlersToElement(element: HTMLElement, cb: Callback) {
  element.addEventListener('click', listener(cb), false);
}

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.mjs';

export function ImageViewer() {
  const [show, setShow] = useState<boolean>(false);
  const [callback, setCallback] = useState<{ c: Callback }>({ c: () => {} });
  const [url, setUrl] = useState<string>('');

  useEffect(() => {
    imageClickHandler = async (s: string, e: Callback) => {
      setTimeout(() => {
        setShow(true);
        setUrl(s);
        setCallback({ c: e });
      }, 1);
    };

    return () => {
      imageClickHandler = () => {};
    };
  }, []);

  useEffect(() => {
    if (!show) {
      callback.c();
    }
  }, [show]);

  const close = () => {
    setShow(false);
    setUrl('');
  };

  return (
    <div key={url} className={'d-none'}>
      <Modal show={show} onHide={close} size={'lg'} backdrop={true} key={url}>
        <Modal.Body className={'h-100 w-100'}>
          <img src={url} className={'w-100'} alt={url} />
        </Modal.Body>
      </Modal>
      {!show && <span />}
    </div>
  );
}

export default function PdfViewer() {
  const [show, setShow] = useState<boolean>(false);
  const [url, setUrl] = useState<string>('');

  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);

  const elementRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(100);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  useEffect(() => {
    pdfClickHandler = (s: string) => {
      setShow(true);
      setUrl(s);
    };

    return () => {
      pdfClickHandler = () => {};
    };
  }, []);

  useEffect(() => {
    if (elementRef.current) {
      setWidth(elementRef.current.getBoundingClientRect().width);
    }
    setPageNumber(1);
  }, [elementRef.current, url, show]);

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size={'xl'}
      backdrop={true}
      fullscreen={'lg-down'}
    >
      <Modal.Header className={'sticky-top'} style={{ backgroundColor: 'var(--bs-body-bg' }}>
        <Button
          onClick={event => setPageNumber(pageNumber - 1)}
          variant={'link'}
          size={'sm'}
          disabled={pageNumber == 1}
        >
          <FontAwesomeIcon icon={faCaretLeft} />
        </Button>
        <span>
          Page {pageNumber} of {numPages}
        </span>
        <span>
          <button className={'btn btn-link link-danger btn-sm'} onClick={event => setShow(false)}>
            <FontAwesomeIcon icon={faCircleXmark} />
          </button>
          <Button
            onClick={event => setPageNumber(pageNumber + 1)}
            variant={'link'}
            size={'sm'}
            disabled={pageNumber == numPages}
          >
            <FontAwesomeIcon icon={faCaretRight} />
          </Button>
        </span>
      </Modal.Header>
      <Modal.Body className={'h-100'}>
        <div className={'viewer h-100  w-100'} ref={elementRef}>
          <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} width={width} />
          </Document>

          <div className={'w-100'} />
        </div>
      </Modal.Body>
    </Modal>
  );
}
