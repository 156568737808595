import dayjs from 'dayjs';

import { Auction } from '@/model/auctions/auction';
import { AuctionProductId } from '@/model/auctions/auction-product-id';
import { AuctionProduct } from '@/model/auctions/auction-product';
import { DataSeriesEntry, DataSeriesEntryFactory } from '@/model/calendar/data-series-entry';
import { InstantAnswers } from '@/model/calendar/instant-answers';
import { CoverageItem } from '@/model/calendar/coverage-item';
import { Observation } from '@/model/calendar/observation';
import { DataRelease } from '@/model/calendar/data-release';
import { AuctionObservation } from '@/model/calendar/auction-observation';

export class NewsEventUtil {
  static mergeObservations(newsEvent: NewsEvent, observation: Observation): boolean {
    let dirty = false;

    const windowStart = dayjs(newsEvent.date).subtract(5, 'minute');
    const windowEnd = dayjs(newsEvent.date).add(5, 'minute');

    const observationDate = dayjs(observation.eventDate);

    if (observationDate.isBefore(windowStart) || observationDate.isAfter(windowEnd)) return false;

    if (newsEvent.dataSeriesEntries) {
      newsEvent.dataSeriesEntries.forEach(dataEntry => {
        if (observation.dataSeriesId === dataEntry.dataSeriesId) {
          dataEntry.actual = observation.value;
          dirty = true;
        }
      });
    }

    return dirty;
  }

  static mergeInstantAnswers(newsEvent: NewsEvent, observation: InstantAnswers): boolean {
    let dirty = false;

    if (newsEvent.instantAnswers) {
      newsEvent.instantAnswers.forEach(instantAnswer => {
        if (instantAnswer.instantAnswersInstanceId === observation.instantAnswersInstanceId) {
          instantAnswer.state = observation.state;
          dirty = true;
        }

        instantAnswer.questions.forEach(question => {
          observation.questions.forEach(answer => {
            if (question.questionId === answer.questionId) {
              question.answer = answer.answer;
              dirty = true;
            }
          });
        });
      });
    }

    return dirty;
  }

  static mergeAuctionData(newsEvent: NewsEvent, observation: AuctionObservation): boolean {
    let dirty = false;

    const windowStart = dayjs(newsEvent.date).subtract(2, 'hour');
    const windowEnd = dayjs(newsEvent.date).add(2, 'hour');

    const observationDate = dayjs(observation.eventDate);

    if (observationDate.isBefore(windowStart) || observationDate.isAfter(windowEnd)) {
      return false;
    }

    if (newsEvent.auction) {
      newsEvent.auction.auctionProducts.forEach(dataEntry => {
        if (
          observation.auctionProductId.type === (dataEntry.id as AuctionProductId).type &&
          observation.auctionProductId.value === (dataEntry.id as AuctionProductId).value
        ) {
          dataEntry.attributes.forEach(value => {
            if (observation.attributeId === value.id) {
              value.value = observation.value;
              dirty = true;
            }
          });
        }
      });
    }

    return dirty;
  }
}

export function NewsEventFactory(event: NewsEvent) {
  if (event.dataSeriesEntries)
    event.dataSeriesEntries = event.dataSeriesEntries.map(DataSeriesEntryFactory);
}

export interface NewsEvent {
  id: string;

  title: string;
  country: string;
  date: string;
  rating: number;

  dataReleaseId?: number;

  dataRelease?: DataRelease;
  auctionProduct?: AuctionProduct;

  reportingPeriod?: string;

  dataSeriesEntries: DataSeriesEntry[];
  auction: Auction;

  instantAnswers?: InstantAnswers[];

  coverage?: CoverageItem[];
}
