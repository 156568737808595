let deferredPrompt: any;
window.addEventListener('beforeinstallprompt', (e: Event) => {
  deferredPrompt = e;
});

function install(): void {
  deferredPrompt && deferredPrompt.prompt();
}

function canInstall(): boolean {
  return !!deferredPrompt;
}

export default {
  install,
  canInstall,
};
