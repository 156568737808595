import Big from 'big.js';

Big.strict = true;

export default function Round({
  value,
  decimalPrecision = 2,
  addTrailingZeros = false,
}: {
  value: any;
  decimalPrecision?: number | null;
  addTrailingZeros?: boolean;
}) {
  const round = (value: any) => {
    if (value == null) return value;

    const stringValue = `${value}`.trim();

    if (/^-?\d+\.?\d*$/.test(stringValue)) {
      if (!isNaN(parseFloat(stringValue))) {
        const numericValue = Big(stringValue);

        if (addTrailingZeros) {
          const precision = decimalPrecision ? decimalPrecision : 2;
          const format = new Intl.NumberFormat('en-US', {
            minimumFractionDigits: precision,
          });

          return format.format(numericValue.toNumber());
        }

        return `${numericValue}`;
      }
    }

    return value;
  };

  return <>{round(value)}</>;
}
