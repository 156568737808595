export const routeMatches = (routeSpec: string, route: string) => {
  const specSegments = routeSpec.split('/').filter(value => value != '');
  const routeSegments = route.split('/').filter(value => value != '');

  if (routeSegments.length != specSegments.length) {
    return false;
  }

  const data: any = {};

  for (let i = 0; i < routeSegments.length; i++) {
    const specSegment = specSegments[i];
    const routeSegment = routeSegments[i];

    const specVariable = /:([a-zA-Z0-9_-]+)/.exec(specSegment);

    if (specVariable) {
      data[specVariable[1]] = routeSegment;
    } else if (specSegment != routeSegment) {
      return false;
    }
  }

  return data;
};
