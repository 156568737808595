import { useEffect, useState } from 'react';
import { NewsWidget } from '@/widgets/news-widget';
import { Layouts } from 'react-grid-layout';
import useWidgetFactory, { WidgetFactory } from '@/hooks/use-widget-factory';
import { MNIDashboard } from '@/model/preferences/myMNIPreferences';

function getAllWidgetIdsFromLayouts(layouts: Layouts): string[] {
  const ids: string[] = [];

  for (let breakpoint in layouts) {
    if (layouts[breakpoint]) {
      layouts[breakpoint].forEach(value => {
        if (!ids.includes(value.i)) ids.push(value.i);
      });
    }
  }
  return ids;
}

function getActiveWidgets(
  activeWidgets: ActiveWidgetsForDashboard | undefined,
  dashboard: MNIDashboard | undefined,
  widgetFactory: WidgetFactory,
): ActiveWidgetsForDashboard | undefined {
  if (!dashboard) return undefined;

  const newActiveWidgets = activeWidgets?.activeWidgets || [];
  const widgetsFromLayout = getAllWidgetIdsFromLayouts(dashboard.layouts);

  const activeWidgetIds = newActiveWidgets.map(value => value.id);

  let widgetAdded = false;

  widgetsFromLayout.forEach(value1 => {
    if (!activeWidgetIds.includes(value1)) {
      const widget = widgetFactory.getWidgetById(value1);
      if (widget) {
        newActiveWidgets.push(widget);
        widgetAdded = true;
      }
    }
  });

  const filteredWidgets = newActiveWidgets.filter(value => widgetsFromLayout.includes(value.id));
  const widgetRemoved = filteredWidgets.length != newActiveWidgets.length;

  if (widgetAdded || widgetRemoved || (!activeWidgets && newActiveWidgets)) {
    return {
      dashboardId: dashboard.id,
      activeWidgets: filteredWidgets,
    };
  } else return activeWidgets;
}

export interface ActiveWidgetsForDashboard {
  dashboardId: string;
  activeWidgets: NewsWidget[];
}

export default function useWidgetsFromLayouts(
  dashboard?: MNIDashboard,
): ActiveWidgetsForDashboard | undefined {
  const [activeWidgets, setActiveWidgets] = useState<ActiveWidgetsForDashboard>();
  const widgetFactory = useWidgetFactory();

  useEffect(() => {
    if (dashboard?.id !== activeWidgets?.dashboardId) setActiveWidgets(undefined);

    setActiveWidgets(getActiveWidgets(activeWidgets, dashboard, widgetFactory));
  }, [dashboard]);

  return activeWidgets;
}
