import { useEffect, useState } from 'react';

import { NewsEvent } from '@/model/calendar/news-event';
import { Article } from '@/model/article';
import { DataTopic } from '@/model/data/data-topic';
import ArticleList from '@/components/articles/article-list';
import { articleApi } from '@/api';
import { metadataMappingService } from '@/services';

export default function RelatedArticles({
  event,
  onArticleClick,
}: {
  event: NewsEvent;
  onArticleClick: (article: Article) => void;
}) {
  let [relatedArticles, setRelatedArticles] = useState<Article[]>();

  useEffect(() => {
    const topics: { [key: string]: DataTopic } = {};

    event.dataRelease?.series.forEach(value => {
      value.topics.forEach(topic => {
        topics[topic.qcode] = topic;
      });
    });
    const sections = metadataMappingService.getConceptItemsFromDataTopics(
      Object.values(topics).map(value => value.qcode),
    );
    if (!relatedArticles || relatedArticles?.length === 0) {
      articleApi
        .getArticlesByIntersection({
          include: sections.subjects,
          keywords: sections.keywords,
          teasers: true,
        })
        .then(page => {
          setRelatedArticles(page.content);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ArticleList
      id={`related-articles-${event.id}`}
      articles={relatedArticles}
      onArticleClick={onArticleClick}
      colorizeDates={true}
      showGenreTags={false}
    />
  );
}
