import { faGavel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { NewsEvent } from '@/model/calendar/news-event';
import RatingIndicator from '@/components/shared/rating-indicator';

export default function EventBadges({ event }: { event: NewsEvent }) {
  return (
    <>
      {event.dataReleaseId && <RatingIndicator rating={event.rating} />}
      {event.auction && <FontAwesomeIcon icon={faGavel} className={'text-success-light'} />}
    </>
  );
}
