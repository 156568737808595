import { useEffect, useReducer } from 'react';
import { messageService } from '@/services';

export default function useSubscription<T>(
  topic: string,
  callback: (message: T, headers: { [key: string]: string }) => void,
) {
  interface Delivery {
    message: T;
    headers: { [key: string]: string };
  }
  interface State {
    count: number;
  }

  const [, dispatch] = useReducer(
    (state: State, message: Delivery): State => {
      callback(message.message, message.headers);

      return { count: state.count + 1 };
    },
    { count: 0 },
    arg => arg,
  );

  useEffect(() => {
    const subscription = messageService.subscribe<T>(topic, (message, headers) => {
      dispatch({ message, headers });
    });

    console.debug('Subscribed to: ', topic);

    return () => {
      console.debug('Unsubscribing from: ', topic);
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
