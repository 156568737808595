import { NewsWidget } from '@/widgets/news-widget';
import { useWidgetPermission } from '@/hooks/use-widget-permission';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUp } from '@fortawesome/free-solid-svg-icons';

export function WidgetUpgradeBadge({ widget }: { widget: NewsWidget }) {
  const { authorized, loadingPermission } = useWidgetPermission(widget);

  if (authorized || loadingPermission) return <></>;

  return (
    <>
      <span className={'text-warning'}>
        <FontAwesomeIcon icon={faCircleUp} />
      </span>
    </>
  );
}
