import { DataSeriesEntryForecast } from '@/model/calendar/data-series-entry-forecast';

export function DataSeriesEntryFactory(entry: DataSeriesEntry): DataSeriesEntry {
  entry.diff = () => {
    const actual = entry.actual;
    const forecast = entry.forecast();

    if (actual === null || actual === undefined) return null;

    if (forecast === null || forecast === undefined) return null;

    return Math.round((parseFloat(actual) - parseFloat(forecast.value)) * 100) / 100;
  };

  entry.forecast = () => {
    return (
      entry.forecasts.find(value => value.source === 'USER') ||
      entry.forecasts.find(value => value.source === 'MNI') ||
      undefined
    );
  };

  entry.addForecast = forecast => {
    const index = entry.forecasts.findIndex(value => value.source === forecast.source);

    if (index < 0) {
      entry.forecasts.push(forecast);

      return forecast;
    } else {
      entry.forecasts[index].value = forecast.value;

      return entry.forecasts[index];
    }
  };

  entry.removeUserForecast = () => {
    const index = entry.forecasts.findIndex(value => value.source === 'USER');

    if (index < 0) {
    } else {
      entry.forecasts.splice(index, 1);
    }
  };

  return entry;
}

export interface DataSeriesEntry {
  display: string;
  actual: string;
  previous: string;

  forecasts: DataSeriesEntryForecast[];

  scale: string;
  type: string;

  dataSeriesId: number;

  previousRevisionEntry?: DataSeriesEntry;

  isPreviousRevision: boolean;
  hasPreviousRevision: boolean;

  hasProvider: boolean;

  forecast(): DataSeriesEntryForecast | undefined;

  diff(): number | null;

  addForecast(forecast: DataSeriesEntryForecast): DataSeriesEntryForecast;

  removeUserForecast(): void;
}
