import { To, useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import {
  LocalWidgetRouterContext,
  LocalWidgetRouterState,
} from '@/widgets/widget-router/local-widget-router';
import type { NavigateOptions } from 'react-router/dist/lib/context';
import { convertWidgetToReactPath } from '@/widgets/widget-router/convert-widget-to-react-path';

export default function useWidgetNavigate() {
  const context = useContext<LocalWidgetRouterState>(LocalWidgetRouterContext);
  const navigate = useNavigate();

  if (context && context.useLocal) {
    return context.navigate;
  }

  console.log('Using widget navigate');

  return (to: To | number, options?: NavigateOptions) => {
    if (typeof to == 'number') return navigate(to);

    if (typeof to == 'string') to = convertWidgetToReactPath(context.basePath, to);

    return navigate(to, options);
  };
}
