import { createContext } from 'react';
import { WidgetHost } from '@/widgets/widget-host';
import { preferencesApi } from '@/api';

export const WidgetHostContext = createContext<WidgetHost>({
  addWidgetById(id: string) {
    console.warn('Noop widget host called');
  },
  removeWidgetById(id: string) {
    console.warn('Noop widget host called');
  },
  get activeWidgetIds(): string[] {
    return [];
  },
  get hostId(): string {
    return 'no-host';
  },
  get currentViewId(): string {
    return 'default-view';
  },
  async getWidgetPreferences<T>(id: string): Promise<T> {
    return (await preferencesApi.getPreferences(`widget-${id}`)) || ({} as T);
  },
  async saveWidgetPreferences<T>(id: string, t: T): Promise<T> {
    return preferencesApi.upsertPreferences(`widget-${id}`, t);
  },
});
