

export enum AnalyticsEvent {

  ARTICLE_BOOKMARKED = "article_bookmarked",
  ARTICLE_UN_BOOKMARKED = "article_un_bookmarked",
  ARTICLE_VIEWED = "article_viewed",
  ARTICLE_POPOUT = "article_popout",
  ARTICLE_MODAL = "article_modal",

  ALERT_PLAYED = "alert_played",
  NOTIFICATION_CLICKED = "notification_clicked",

  SCROLLER_FILTERS_CHANGED = "scroller_filters_changed",

  WIDGET_ADDED = "widget_added",
  WIDGET_REMOVED = "widget_removed",
  WIDGET_SHARED = "widget_shared",
  WIDGET_POPOUT = "widget_popout",

  DASHBOARD_CHANGED = "dashboard_changed",
  DASHBOARD_CREATED = "dashboard_created",
  DASHBOARD_DELETED = "dashboard_deleted",
  DASHBOARD_SHARED = "dashboard_shared",

  HSC_EVENT_CLICKED = "hsc_event_clicked",
  HSC_FORECAST_EDITED = "hsc_forecast_edited",
  HSC_READER_SCHEDULED = "hsc_reader_scheduled",
  HSC_DATA_RECEIVED = "hsc_data_received",
  HSC_EVENT_ADDED_TO_CALENDAR = "hsc_event_added_to_calendar",

  PINNED_ARTICLE_COLOR_CHANGE = "pinned_article_color_change",
  PINNED_ARTICLE_HISTORY_SHOWN = "pinned_article_history_shown",

  PODCAST_PLAYED = "podcast_played",

  WORLD_CLOCK_LOCATION_ADDED = "world_clock_location_added",
  WORLD_CLOCK_LOCATION_REMOVED = "world_clock_location_removed",

  SEARCH_ENTERED = "search_entered"

}
