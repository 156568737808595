import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

function InfoTooltip({ message }: { message: string }) {
  const renderTooltip = (props: any) => (
    <Tooltip id="info-tooltip" {...props}>
      {message}
    </Tooltip>
  );

  return (
    <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
      <span className="d-inline-block">
        <FontAwesomeIcon icon={faCircleInfo} style={{ cursor: 'pointer', fontSize: '15px' }} />
      </span>
    </OverlayTrigger>
  );
}

export default InfoTooltip;
