import { InstantAnswers } from '@/model/calendar/instant-answers';

import styles from '@/components/data-calendar/details/calendar-event-details.module.css';

function AnswerText({ text }: { text?: string }): JSX.Element {
  if (!text) return <></>;

  if (text.toLowerCase() === 'yes') return <span className={styles.answerYes}>{text}</span>;
  else if (text.toLowerCase() === 'no') return <span className={styles.answerNo}>{text}</span>;
  else return <span className={styles.answerNA}>{text}</span>;
}

export default function InstantAnswersTable({
  instantAnswers,
}: {
  instantAnswers: InstantAnswers;
}) {
  return (
    <table className={'table table-sm small text-light'}>
      <thead>
        <tr>
          <th>Question</th>
          <th>Answer</th>
        </tr>
      </thead>
      <tbody>
        {instantAnswers.questions.map(value => (
          <tr key={`${value.questionId}-ia`}>
            <td className={'calendar-section-header '}>{value.questionText}</td>
            <td>
              <AnswerText text={value.answer?.textValue} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
