import { NewsWidget } from '@/widgets/news-widget';
import { WidgetHost } from '@/widgets/widget-host';
import { RouteObject } from 'react-router-dom';
import { NewsWidgetFactory, WidgetMenuEntry } from '@/widgets/news-widget-factory';
import SingleCalendar from '@/components/calendars/single-calendar';
import SingleCalendarTitle from '@/components/calendars/single-calendar-title';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { routeMatches } from '@/widgets/widget-router/route-matches';

const urlPath = '/events/:calendarSlug';

export class SingleCalendarWidgetFactory implements NewsWidgetFactory {
  get path(): string {
    return urlPath;
  }

  get headerPanel(): string | JSX.Element {
    return (
      <>
        <FontAwesomeIcon icon={faCalendar} /> Calendars
      </>
    );
  }

  matchesTemplate(s: string): boolean {
    return !!routeMatches(urlPath, s);
  }

  createInstance(s: string): NewsWidget {
    const match = routeMatches(urlPath, s);

    if (match) return new SingleCalendarWidget(match.calendarSlug);

    throw new Error('invalid url');
  }

  getListableUrls(): WidgetMenuEntry[] {
    return [
      {
        url: '/events/key-events-calendar',
        name: 'Key Events Calendar',
      },
      {
        name: 'Central Bank Calendars',
        children: [
          {
            url: '/events/fomc-meeting-calendar',
            name: 'FOMC Meeting Calendar',
          },
          {
            url: '/events/boe-meeting-calendar',
            name: 'BOE Meeting Calendar',
          },
          {
            url: '/events/boc-meeting-calendar',
            name: 'BOC Meeting Calendar',
          },
          {
            url: '/events/bank-of-italy-meeting-calendar',
            name: 'Bank of Italy Meeting Calendar',
          },
        ],
      },
      {
        name: 'Auction Calendars',
        children: [
          {
            name: 'Eurozone/UK Auction Bond Calendar',
            url: '/pinned-series/mni-section:480257876',
          },
          {
            name: 'Eurozone/UK Government T-Bill Auction Calendar',
            url: '/pinned-series/mni-section:480257877',
          },
          {
            name: 'US Treasury/Agency Auction Calendar',
            url: '/pinned-series/mni-section:480257875',
          },
        ],
      },
    ];
  }
}

export class SingleCalendarWidget implements NewsWidget {
  defaultTitle: string = 'Calendar';
  constructor(private calendar: string) {}

  async hasPermission(permissions: string[]) {
    return true;
  }

  get headerPanel(): JSX.Element {
    return (
      <>
        <FontAwesomeIcon icon={faCalendar} /> <SingleCalendarTitle calendarSlug={this.calendar} />
      </>
    );
  }

  headerActions({ children }: { children?: React.ReactNode }): JSX.Element {
    return <>{children}</>;
  }

  get id(): string {
    return '/events/' + this.calendar;
  }

  initialize(host: WidgetHost): void {}

  get shortTitle(): JSX.Element {
    return (
      <>
        <FontAwesomeIcon icon={faCalendar} /> {this.calendar}
      </>
    );
  }

  get routes(): RouteObject[] {
    return [
      {
        path: '/',
        element: <SingleCalendar calendarSlug={this.calendar} />,
      },
    ];
  }

  get defaultRoute(): string {
    return `/`;
  }
}
