import { Navigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { BarLoader } from 'react-spinners';
import theme from '@/index.module.scss';
import { authService } from '@/services';

export default function TokenExchangePage() {
  const { token } = useParams();

  const [state, setState] = useState<'LOGGED_IN' | 'LOGGING_IN' | 'ERROR'>('LOGGING_IN');

  useEffect(() => {
    if (token) {
      authService.logout().then(value => {
        authService.loginWithToken(token as string).then(
          value => {
            setState('LOGGED_IN');
          },
          reason => {
            setState('ERROR');
          },
        );
      });
    } else {
      setState('ERROR');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (state === 'ERROR') {
    window.location.href = 'https://marketnews.com/r/kappa/api/v2/reader/launch-app';
    return (
      <div className={'m-5'}>
        <BarLoader color={theme.primary} width={'100%'} />
      </div>
    );
  }

  if (state === 'LOGGED_IN') {
    return <Navigate to={'/'} />;
  }

  return (
    <>
      <div className={'m-5'}>
        <BarLoader color={theme.primary} width={'100%'} />
      </div>
    </>
  );
}
