import { createContext } from 'react';
import { WidgetContainer } from '@/widgets/widget-container';

export const WidgetContainerContext = createContext<WidgetContainer>({
  getPreferences<T>(): T | undefined {
    return undefined;
  },
  setPreferences<T>(state: T): void {},
  updatePreferences<T>(state: Partial<T>): void {},
  updateState<T>(state: Partial<T>): void {},
  setState<T>(state: T | undefined) {
    console.warn('Noop widget container called', 'setState', state);
  },
  getState<T>(): T | undefined {
    return undefined;
  },
  get widgetId(): string | undefined {
    return undefined;
  },
  close() {
    console.warn('Noop widget container called', 'close');
  },
});
