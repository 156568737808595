import dayjs, { Dayjs } from 'dayjs';

import { NewsEvent } from '@/model/calendar/news-event';
import CountryFlag from '@/components/shared/country-flag';
import DateFormat from '@/components/shared/date-format';
import EventBadges from '@/components/shared/event-badges';
import { CoverageBadge } from '@/components/data-calendar/coverage/coverage-badge';

import style from '@/components/data-calendar/list/calendar-week-view.module.scss';

interface DataCalendarWeekViewProps {
  calendar?: NewsEvent[];
  setEvent: Function;
  start: Dayjs;
}

export default function CalendarWeekView({ calendar, setEvent, start }: DataCalendarWeekViewProps) {
  const week: { [key: string]: { date: Dayjs; events: NewsEvent[] } } = {};

  start = start.startOf('week');

  for (let i = 0; i < 7; i++) {
    let date = dayjs(start).tz().add(i, 'day');
    const day = date.format('ddd');
    week[day] = { date: date, events: [] };
  }

  calendar?.forEach(value => {
    const day = dayjs(value.date).tz().format('ddd');
    week[day].events.push(value);
  });

  const DayCell = ({
    date,
    events,
    className,
  }: {
    date: Dayjs;
    events: NewsEvent[];
    className: string;
  }) => {
    return (
      <div
        className={`${
          date.format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD') ? 'bg-body-secondary' : ''
        } ${className}  ${style.dayColumn}`}
      >
        <div className={`${style.weekHeader}`}>
          {date.format('ddd')}{' '}
          <span className={'text-muted small ms-auto'}>{date.format('M/D')}</span>
        </div>
        {events?.map(value => (
          <div key={`${date}-${value.id}`} className={`small text-text-dark ${style.weekEvent} `}>
            <CountryFlag country={value.country} height={13} />
            <span className={`${style.weekEventTitle}`} onClick={() => setEvent(value)}>
              <span className={'fw-bold text-muted'}>
                {' '}
                <DateFormat format={'h:mm a'} date={value.date} />{' '}
              </span>
              <EventBadges event={value} />{' '}
              {value?.auction?.auctionProducts
                .map(
                  auction =>
                    auction.attributes.find(attribute => attribute.id?.toLowerCase() === 'display')
                      ?.value,
                )
                .filter(item => item)[0] || value.title}
              <CoverageBadge
                event={value}
                coverageType={'PREVIEW'}
                className={'bg-tertiary m-1'}
                label={'P'}
              />
              <CoverageBadge
                event={value}
                coverageType={'RELATED'}
                className={'bg-tertiary-600 m-1'}
                label={'C'}
              />
              <CoverageBadge
                event={value}
                coverageType={'REVIEW'}
                className={'bg-primary-500 m-1'}
                label={'R'}
              />
            </span>
          </div>
        ))}
        {(!events || events?.length === 0) && <div className={'text-muted m-2'}>No Events</div>}
      </div>
    );
  };

  return (
    <>
      <div className={'row m-1 g-0'}>
        <DayCell
          date={week['Sun'].date}
          events={week['Sun'].events}
          className={'col-container-md-1'}
        />
        <DayCell
          date={week['Mon'].date}
          events={week['Mon'].events}
          className={'col-container-md-2'}
        />
        <DayCell
          date={week['Tue'].date}
          events={week['Tue'].events}
          className={'col-container-md-2'}
        />
        <DayCell
          date={week['Wed'].date}
          events={week['Wed'].events}
          className={'col-container-md-2'}
        />
        <DayCell
          date={week['Thu'].date}
          events={week['Thu'].events}
          className={'col-container-md-2'}
        />
        <DayCell
          date={week['Fri'].date}
          events={week['Fri'].events}
          className={'col-container-md-2'}
        />
        <DayCell
          date={week['Sat'].date}
          events={week['Sat'].events}
          className={'col-container-md-1'}
        />
      </div>
    </>
  );
}
