import { NewsEvent } from '@/model/calendar/news-event';

export function CoverageBadge({
  event,
  coverageType,
  className,
  label,
}: {
  event: NewsEvent;
  coverageType: string;
  className: string;
  label: string;
}) {
  if (!event.coverage) return <></>;

  let hasCoverageType = false;

  event.coverage.forEach(value => {
    if (value.coverageType.name === coverageType) hasCoverageType = true;
  });

  return (
    (hasCoverageType && (
      <span className={`badge badge-pill small text-base-light ${className}`}>{label}</span>
    )) || <></>
  );
}
