import { Button, Modal } from 'react-bootstrap';
import { useState } from 'react';
import SettingsMenu from '@/components/settings/settings-menu';

export default function DashboardSettingsModal({
  showSettingsModal,
  setShowSettingsModal,
}: {
  showSettingsModal: boolean;
  setShowSettingsModal: (b: boolean) => void;
}) {
  return (
    <Modal
      show={showSettingsModal}
      onHide={() => {
        setShowSettingsModal(false);
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className={'mni-dock'}>
        <SettingsMenu />
      </Modal.Body>
    </Modal>
  );
}
