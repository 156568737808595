import { useEffect, useRef } from 'react';

import { Article } from '@/model/article';
import DateFormat from '@/components/shared/date-format';
import UpgradePanel from '@/components/shared/upgrade-panel';
import TaggedHeadline from '@/components/shared/tagged-headline';
import BookmarkButton from '@/components/bookmarks/bookmark-button';
import ArticleBodyDisplay from '@/components/articles/article-body-display';

export default function ArticleView({
  article,
  onClose,
  showBookmark = true,
  showBack = true,
  showPin = true,
}: {
  article: Article;
  onClose: Function;
  showBookmark?: boolean;
  showBack?: boolean;
  showPin?: boolean;
}) {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (ref && article) {
      const anchors = ref.current?.getElementsByTagName<'a'>('a');

      if (anchors) {
        for (const a of anchors) {
          a.setAttribute('target', '_blank');
        }
      }
    }
  }, [ref, article]);

  return (
    <div className="container-element h-100">
      <div className="container-responsive-text h-100 overflow-auto m-2">
        <div className={'sticky-top'}>
          <div className={'d-flex '}>
            <div className={'me-auto'}>
              <h3>
                <TaggedHeadline headline={article.headline} />
              </h3>
            </div>

            <div className={'text-end'}>
              <span className={'pt-1'}>
                {showBookmark && (
                  <span className={'me-1'}>
                    <BookmarkButton article={article} />
                  </span>
                )}
              </span>
            </div>
          </div>
        </div>

        <hr />

        <div className={'d-flex justify-content-between small'}>
          <div className={'text-muted'}>
            <DateFormat format={'MMM D, YYYY, h:mm a'} date={article.firstcreated} />
          </div>
          <em>By {article.byline}</em>
        </div>

        <div className={'mt-3'}>
          <ArticleBodyDisplay xhtml={article.body_xhtml} />
        </div>

        {article.representationtype === 'partial' && (
          <div className={'text-center'}>
            <div className={'col-container-sm-8 offset-container-sm-2'}>
              <div className={'card bg-black shadow-lg border border-primary'}>
                <UpgradePanel contentName={`Article: ${article.headline}`} />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
