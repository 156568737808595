import dayjs, { Dayjs } from 'dayjs';

import { storageService } from '@/services/storage-service';
import { Observation } from '@/model/calendar/observation';
import {analyticsService, messageService} from '@/services/index';
import { MessageServiceConstants } from '@/services/messaging/message-service-constants';
import { DataSeriesReading } from '@/services/reader/data-series-reading';
import {AnalyticsEvent} from '@/services/analytics/analytics-event';

let dataSeriesReading: DataSeriesReading | undefined;

let scheduledTask: any;

const READING_STORAGE_KEY = 'scheduled_reading';

//pull up saved reading if it exists
storageService.getValue<DataSeriesReading>(READING_STORAGE_KEY).then(value => {
  console.log('Loading scheduled reading');

  if (value && dayjs(value.date).isAfter(dayjs())) scheduleReading(value);
});

messageService.subscribe<Observation[]>(MessageServiceConstants.SELECT_TOPIC, observations => {
  observations.forEach(observation => {
    if (observation.dataSeriesId === dataSeriesReading?.dataSeriesId) {
      console.log('Reading data series', observation.dataSeriesId);
      readText(`${observation.value}`);
      setScheduledReading();
    }
  });
});

function readText(text: string) {
  text = text.toLowerCase().replaceAll(/m\/m/g, ', month-on-month');
  text = text.toLowerCase().replaceAll(/y\/y/g, ', year-on-year');
  text = text.toLowerCase().replaceAll(/w\/w/g, ', week-on-week');
  text = text.toLowerCase().replaceAll(/ex\./g, 'excluding');

  window.speechSynthesis.speak(new SpeechSynthesisUtterance(text));
}

function readScheduledHeadingOr(text: string) {
  if (dataSeriesReading) readText(dataSeriesReading?.text || '');
  else readText(text);
}

function setScheduledReading(r?: DataSeriesReading) {
  dataSeriesReading = r;
  storageService.saveValue(READING_STORAGE_KEY, dataSeriesReading);

  if (dataSeriesReading) {
    dataSeriesReading.date = new Date(dataSeriesReading.date);
  }
}

function scheduleReading(dataSeriesReading?: DataSeriesReading) {

  clearTimeout(scheduledTask);

  setScheduledReading(dataSeriesReading);

  if (!dataSeriesReading) return;

  analyticsService.recordAnalyticsEvent(AnalyticsEvent.HSC_READER_SCHEDULED, {
    text:dataSeriesReading.text,
    dataSeries:dataSeriesReading.dataSeriesId,
    date:dataSeriesReading.date
  })

  let eventTime = dataSeriesReading.date.getTime();
  let leadTime = eventTime - new Date().getTime() - 5000;

  scheduledTask = setTimeout(() => {
    readText(dataSeriesReading?.text || '');
  }, leadTime);

  console.log(
    'Scheduled reading',
    dataSeriesReading,
    dataSeriesReading.date,
    dayjs.tz(eventTime).tz(),
  );

  console.log('Reading announcement');

  readText(`Will read '${dataSeriesReading.text}' at ${dayjs.tz(eventTime).format('hh:mm a')}`);
}

function getDataSeriesReading(): DataSeriesReading | undefined {
  if (dataSeriesReading) {
    //if in future get it, else clear the reader
    if (dataSeriesReading.date.getTime() > new Date().getTime() - 30_000) return dataSeriesReading;
    else scheduleReading();
  }
}

function isReading(dataSeriesId: number, date: Date | string | number | Dayjs): boolean {
  if (!dataSeriesReading) return false;

  date = dayjs(date).toDate();

  return (
    dataSeriesReading.dataSeriesId === dataSeriesId &&
    dataSeriesReading.date.getTime() === date.getTime()
  );
}

export default {
  getDataSeriesReading,
  isReading,
  readScheduledHeadingOr,
  scheduleReading,
};
