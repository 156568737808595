import React, { useEffect, useState } from 'react';
import { RouteObject } from 'react-router-dom';
import type { NavigateOptions } from 'react-router/dist/lib/context';
import { NewsWidget } from '@/widgets/news-widget';
import { routeMatches } from '@/widgets/widget-router/route-matches';
import Loading from '@/components/shared/loading';

export interface WidgetNavigateFunction {
  (to: string, options?: NavigateOptions): void;
  (delta: number): void;
}
export interface LocalWidgetRouterState {
  path: string;
  routes: RouteObject[];
  route?: RouteObject;
  navigate: WidgetNavigateFunction;
  useLocal: boolean;
  loaderData: unknown;
  params: any;
  basePath: string;
  loading: boolean;
}

export const LocalWidgetRouterContext = React.createContext<LocalWidgetRouterState>(
  {} as LocalWidgetRouterState,
);

export default function LocalWidgetRouter({
  widget,
  onNavigate,
  path,
}: React.PropsWithChildren<{
  widget: NewsWidget;
  onNavigate: (s: string) => void;
  path?: string;
}>) {
  const navigateToPath = async (p: string) => {
    let route: RouteObject = undefined as unknown as RouteObject;
    let data: any = undefined;

    widget.routes.forEach(value => {
      const match = routeMatches(value.path || '', p);

      if (match) {
        data = match;
        route = value;
      }
    });

    let loaderData;

    setState({
      ...state,
      loading: true,
    });

    if (route && route.loader) {
      try {
        loaderData = await route.loader({ params: data } as any);
      } catch (e) {
        setState({
          ...state,
          loading: false,
        });

        throw e;
      }
    }

    setState({
      ...state,
      loaderData,
      path: p,
      route,
      params: data,
      loading: false,
    });
  };

  useEffect(() => {
    if (!path || path == state.path) return;

    setState({
      ...state,
      path,
    });
  }, [path]);

  const navigate: WidgetNavigateFunction = (to: string | number, options?: NavigateOptions) => {
    (async () => {
      if (typeof to == 'string') {
        setHistory([...history, state.path]);
        await navigateToPath(to);
      } else {
        if (history.length > 0) {
          const p = history.pop();
          if (p) {
            await navigateToPath(p);
            setHistory(history);
          } else await navigateToPath(widget.defaultRoute);
        } else await navigateToPath(widget.defaultRoute);
      }
    })().then(value => {
      console.log('Navigation done', to, state.path, history);
    });
  };

  const [history, setHistory] = useState<string[]>([]);
  const [state, setState] = useState<LocalWidgetRouterState>({
    path: path || widget.defaultRoute,
    routes: widget.routes,
    navigate,
    useLocal: true,
    loaderData: undefined,
    params: undefined,
    basePath: widget.id,
    loading: false,
  });

  useEffect(() => {
    navigateToPath(path || widget.defaultRoute).then(value =>
      console.log('Finished initial navigation:', widget.id, path || widget.defaultRoute),
    );
  }, []);
  useEffect(() => {
    onNavigate(state.path);
  }, [state]);

  return (
    <LocalWidgetRouterContext.Provider value={state}>
      {state.route?.element || (
        <>
          {!state.loading && (
            <div>
              Path not found: {state.path}{' '}
              <a
                onClick={event => {
                  event.preventDefault();
                  event.stopPropagation();
                  navigate(-1);
                }}
                href={''}
              >
                Back
              </a>
            </div>
          )}
          {state.loading && <Loading />}
        </>
      )}
    </LocalWidgetRouterContext.Provider>
  );
}
