import { useEffect, useRef } from 'react';

export enum CheckboxState {
  Checked,
  Unchecked,
  Indeterminate,
}

export default function Checkbox({
  onChange,
  value,
  className = '',
  disabled = false,
}: {
  onChange: (a: boolean) => void;
  value: CheckboxState;
  className: string;
  disabled: boolean;
}) {
  const checkboxRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!checkboxRef.current) return;

    if (value === CheckboxState.Checked) {
      checkboxRef.current.checked = true;
      checkboxRef.current.indeterminate = false;
    } else if (value === CheckboxState.Unchecked) {
      checkboxRef.current.checked = false;
      checkboxRef.current.indeterminate = false;
    } else if (value === CheckboxState.Indeterminate) {
      checkboxRef.current.checked = false;
      checkboxRef.current.indeterminate = true;
    }
  }, [value]);

  return (
    <input
      ref={checkboxRef}
      type="checkbox"
      onChange={event => onChange(event.target.checked)}
      className={className}
      disabled={disabled}
    />
  );
}
