import { DataSeriesEntry } from '@/model/calendar/data-series-entry';
import DataRowEntry from '@/components/data-calendar/rows/data-row-entry';

export default function EventDetailsDataTable({
  dataSeriesEntries,
  date,
}: {
  dataSeriesEntries: DataSeriesEntry[];
  date: string;
}) {
  return (
    <table className={'table table-sm '}>
      <thead>
        <tr>
          <th className={'bg-quaternary text-text-dark'}>Figure</th>
          <th className={'bg-quaternary text-text-dark'}></th>
          <th className={'bg-quaternary text-text-dark'}>Fcst</th>
          <th className={'bg-quaternary '}>Act</th>
          <th className={'bg-quaternary text-text-dark'}>Diff</th>
          <th className={'bg-quaternary text-text-dark'}>
            Prev / <span className={'text-body'}>Rev</span>{' '}
          </th>
        </tr>
      </thead>
      <tbody>
        {dataSeriesEntries
          .filter(value => !value.isPreviousRevision)
          .map(value => (
            <tr key={'row-' + value.dataSeriesId}>
              <DataRowEntry dataSeriesEntry={value} date={date} isNext={false} />
            </tr>
          ))}
      </tbody>
    </table>
  );
}
