import { useEffect, useReducer } from 'react';

interface State {
  count: number;
}

export default function useTimer(
  interval: number,
  callback: () => void,
  fireImmediately = false,
  name?: string,
) {
  const [, dispatch] = useReducer(
    (state: State): State => {
      if (name) console.debug('Triggering timer', name, state.count);
      callback();

      return { count: state.count + 1 };
    },
    { count: 0 },
    arg => arg,
  );

  useEffect(() => {
    if (fireImmediately) {
      dispatch();
    }

    let timer = setInterval(() => {
      dispatch();
    }, interval);

    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
