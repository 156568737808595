export default function EmptyStateIndicator({ text }: { text: string | JSX.Element }) {
  return (
    <div className={'text-center'}>
      <div
        className={
          'bg-body-secondary w-100 d-flex flex-column justify-content-around align-items-center'
        }
        style={{ borderRadius: '50%', aspectRatio: 1 }}
      >
        <img src={'/logo-white.svg'} width={'50%'} />
      </div>
      <p>{text}</p>
    </div>
  );
}
