import React, { useEffect, useState } from 'react';
import { Credentials } from '@/model/auth/credentials';
import { authService } from '@/services';

export default function RequirePermissions({
  allow,
  deny,
  children,
  fallback,
}: React.PropsWithChildren<{
  allow?: string | RegExp;
  deny?: string | RegExp;
  fallback?: JSX.Element;
}>) {
  const [credentials, setCredentials] = useState<Credentials>();

  useEffect(() => {
    authService.onTokenAvailable().then(value => {
      setCredentials(authService.getCredentials());
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const allowMatching = credentials?.body?.permissions?.reduce((accumulator, value) => {
    if (allow instanceof RegExp) {
      return allow.test(value) || accumulator;
    }

    return allow === value || accumulator;
  }, !allow);

  const denyMatching = credentials?.body?.permissions?.reduce((accumulator, value) => {
    if (deny instanceof RegExp) {
      return deny.test(value) || accumulator;
    }

    return deny === value || accumulator;
  }, false);

  if (!denyMatching && allowMatching) return <>{children}</>;

  if (credentials && fallback) return fallback;

  return <></>;
}
