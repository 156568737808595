import { Page } from '@/model/page';

export class PageUtil {
  static async getAllPages<T>(f: (currentPage: number) => Promise<Page<T>>): Promise<T[]> {
    let result: T[] = [];
    let currentPage = 0;
    let totalPages = 1;
    do {
      let page = await f(currentPage);

      page.content.forEach(value => result.push(value));

      totalPages = page.totalPages;

      currentPage++;
    } while (currentPage < totalPages);

    return result;
  }
}
