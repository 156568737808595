import React, { useState } from 'react';

import { NewsEvent } from '@/model/calendar/news-event';
import CalendarEventDetails from '@/components/data-calendar/details/calendar-event-details';
import HotList from '@/components/hot-list/hot-list';
import { WidgetHost } from '@/widgets/widget-host';
import { NewsWidget } from '@/widgets/news-widget';
import { RouteObject } from 'react-router-dom';

function HotListMainView() {
  const [event, setEvent] = useState<NewsEvent>();

  if (event)
    return (
      <div className={'h-100 overflow-auto'}>
        <CalendarEventDetails
          newsEvent={event}
          onArticleClick={() => {}}
          onClose={() => setEvent(undefined)}
        />
      </div>
    );

  return (
    <div className={'h-100 overflow-auto'}>
      <HotList eventClick={event => setEvent(event)} />
    </div>
  );
}

export class HotListWidget implements NewsWidget {
  defaultTitle: string = 'Hot List';
  readonly id = '/hot-list';

  async hasPermission(permissions: string[]) {
    return true;
  }

  initialize(host: WidgetHost) {}
  get headerPanel(): JSX.Element {
    return <>🔥 Hot List</>;
  }

  headerActions({ children }: { children?: React.ReactNode }): JSX.Element {
    return <>{children}</>;
  }

  get shortTitle(): JSX.Element {
    return <>🔥</>;
  }

  get mainPanel(): JSX.Element {
    return <HotListMainView />;
  }

  get routes(): RouteObject[] {
    return [
      {
        path: `/hot-list`,
        element: <HotListMainView />,
      },
    ];
  }

  get defaultRoute(): string {
    return `/hot-list`;
  }
}
