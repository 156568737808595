import axios from 'axios';

async function getShareLink(body: any): Promise<string | null> {
  const res = await axios.post(`/api/share`, body);

  if (res.status === 200) {
    return res.data.id;
  }

  return null;
}

async function getShareValue<T>(id: string): Promise<T | null> {
  return (await axios.get(`/api/share/${id}`)).data.share;
}

export default {
  getShareLink,
  getShareValue,
};
