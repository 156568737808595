import { useLoaderData, useParams } from 'react-router-dom';
import { useContext } from 'react';
import {
  LocalWidgetRouterContext,
  LocalWidgetRouterState,
} from '@/widgets/widget-router/local-widget-router';
import type { Params } from '@remix-run/router';

export default function useWidgetParams() {
  const context = useContext<LocalWidgetRouterState>(LocalWidgetRouterContext);
  const d = useParams();

  if (context.useLocal) {
    return context.params;
  }

  return d;
}
