import { Button, Modal } from 'react-bootstrap';

export default function DashboardDeleteModal({
  showDeleteDashboardModal,
  setShowDeleteDashboardModal,
  onConfirm,
}: {
  showDeleteDashboardModal: boolean;
  setShowDeleteDashboardModal: (b: boolean) => void;
  onConfirm: () => void;
}) {
  return (
    <Modal
      show={showDeleteDashboardModal}
      onHide={() => {
        setShowDeleteDashboardModal(false);
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="p-3">
        <h4 className="text-center">Delete dashboard</h4>
        <div className="text-center my-3">
          <p>Are you sure you want to delete this dashboard?</p>
          <p>You will not be able to undo this operation.</p>
        </div>
        <Modal.Footer>
          <Button
            style={{ height: '40px', fontFamily: 'Inter' }}
            className="btn btn-warning"
            onClick={() => {
              setShowDeleteDashboardModal(false);
            }}
          >
            Cancel
          </Button>
          <Button
            style={{ height: '40px', fontFamily: 'Inter' }}
            className="btn btn-error"
            onClick={() => {
              onConfirm();

              setShowDeleteDashboardModal(false);
            }}
          >
            Delete Permanently
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  );
}
