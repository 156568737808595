import { WidgetHost } from '@/widgets/widget-host';
import { NewsWidget } from '@/widgets/news-widget';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbtack, faThumbTackSlash, faX } from '@fortawesome/free-solid-svg-icons';
import PinnedArticlePage from '@/pages/news/pinned-article-page';
import { PinnedWidgetPreferences, PinnedWidgetState } from './pinned-series-widget';
import { WidgetContainerContext } from '@/widgets/widget-container/widget-container-context';
import { RouteObject } from 'react-router-dom';
import articleApi from '@/api/article-api';
import ColorPicker from '../../../components/pins/pinned-color-select';
import {analyticsService} from '@/services';
import {AnalyticsEvent} from '@/services/analytics/analytics-event';

export class PinnedArticleWidget implements NewsWidget<PinnedWidgetState, PinnedWidgetPreferences> {
  defaultTitle: string = 'Pinned Article';
  constructor(private readonly articleId: string) {}

  get id(): string {
    return `/pinned/${this.articleId}`;
  }

  async hasPermission(permissions: string[]) {
    return true;
  }

  initialize(host: WidgetHost) {}
  get headerPanel(): JSX.Element {
    return (
      <WidgetContainerContext.Consumer>
        {widgetContainer => (
          <>
            <span className={'w-100   position-relative'}>
              {widgetContainer.getState<PinnedWidgetState>()?.title ?? this.defaultTitle}
            </span>
            <span
              className={'position-fixed  w-100 text-center overflow-visible '}
              style={{
                top: -5,
                left: 0,
                zIndex: 1000000,
                pointerEvents: 'none',
                overflow: 'visible !important',
                overflowClipMargin: 'unset',
                overflowAnchor: 'unset',
                overflowBlock: 'visible',
              }}
            >
              <img src={'/tape.png'} width={'150px'} alt={'pin'} />
            </span>
          </>
        )}
      </WidgetContainerContext.Consumer>
    );
  }

  headerActions({ children }: { children?: React.ReactNode }): JSX.Element {
    return (
      <WidgetContainerContext.Consumer>
        {widgetContainer => (
          <>
            <button className={'btn btn-link p-2'} onClick={widgetContainer.close}>
              <FontAwesomeIcon icon={faThumbTackSlash} />
            </button>
            {children}
            <ColorPicker
              selectedColor={
                widgetContainer.getPreferences<PinnedWidgetPreferences>()?.color ?? 'note-blue'
              }
              handleColorSelect={color => {
                analyticsService.recordAnalyticsEvent(AnalyticsEvent.PINNED_ARTICLE_COLOR_CHANGE, {
                  pinnedArticleId: this.articleId,
                  color:color
                })
                widgetContainer.updatePreferences<PinnedWidgetPreferences>({ color });
              }}
            />
          </>
        )}
      </WidgetContainerContext.Consumer>
    );
  }

  get shortTitle(): JSX.Element {
    return (
      <>
        <FontAwesomeIcon icon={faThumbtack} />
      </>
    );
  }

  containerClass(state?: PinnedWidgetState, preferences?: PinnedWidgetPreferences) {
    return `${preferences?.color ?? 'note-blue'} sticky-note shadow-sm`;
  }

  get routes(): RouteObject[] {
    return [
      {
        path: '',
        element: (
          <WidgetContainerContext.Consumer>
            {widgetContainer => (
              <PinnedArticlePage
                className={
                  widgetContainer.getPreferences<PinnedWidgetPreferences>()?.color ?? 'note-blue'
                }
              />
            )}
          </WidgetContainerContext.Consumer>
        ),
        loader: async ({}) => {
          try {
            const article = await articleApi.getArticle(this.articleId || '');
            const title = article.headline;

            return { article, title, loaded: true };
          } catch (e) {
            return e;
          }
        },
      },
    ];
  }

  get defaultRoute(): string {
    return '';
  }
}
