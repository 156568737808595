import { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';

import { DataTopic } from '@/model/data/data-topic';
import { NewsEvent } from '@/model/calendar/news-event';
import { TopicMenu } from '@/components/data-calendar/topic-menu-node';
import { TopicFilter } from '@/components/data-calendar/topic-filter';

import styles from '@/components/data-calendar/calendar.module.scss';
import { selectApi } from '@/api';
import { storageService } from '@/services';

export interface CalendarEventFilter {
  filtersActive: boolean;
  filter: (event: NewsEvent) => boolean;
}

export interface Filters {
  selectedTopics: TopicFilter;
  selectedRegions: TopicFilter;
}

export default function CalendarFilters({
  onEventFilterChange,
  showFilters,
}: {
  onEventFilterChange: (f: CalendarEventFilter) => void;
  showFilters: boolean;
}) {
  const [topicTree, setTopicTree] = useState<DataTopic[]>([]);
  const [selectedTopics, setSelectedTopics] = useState<TopicFilter>();
  const [selectedRegions, setSelectedRegions] = useState<TopicFilter>();

  useEffect(() => {
    storageService.getValue<Filters>('filters').then(value => {
      if (value) {
        setSelectedRegions(new TopicFilter(value.selectedRegions));
        setSelectedTopics(new TopicFilter(value.selectedTopics));
      } else {
        setSelectedRegions(new TopicFilter());
        setSelectedTopics(new TopicFilter());
      }
    });

    selectApi.getTopicTree().then(topics => {
      setTopicTree(topics);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!selectedTopics || !selectedRegions) return;

    storageService
      .saveValue('filters', {
        selectedRegions,
        selectedTopics,
      })
      .then(value => {
        onEventFilterChange({
          filtersActive: !(selectedTopics.allSelected && selectedRegions.allSelected),
          filter: event => {
            return (
              selectedTopics.allowRelease(event.dataRelease) &&
              selectedRegions.allowRelease(event.dataRelease) &&
              selectedTopics.allowAuction(event.auctionProduct) &&
              selectedRegions.allowAuction(event.auctionProduct)
            );
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTopics, selectedRegions]);

  return (
    <>
      {showFilters && (
        <div className={''}>
          <div className={'d-flex flex-container-sm-row flex-container-column'}>
            <Dropdown className={'mt-1 d-container-block d-container-sm-inline flex-fill'}>
              <Dropdown.Toggle
                variant="outline-secondary"
                id="dropdown-basic"
                size="sm"
                className={`w-100  ${styles.controlButton}`}
              >
                Topics
              </Dropdown.Toggle>
              <Dropdown.Menu variant={'theme'} className={`border-secondary ${styles.topicList} `}>
                {topicTree && selectedTopics && (
                  <TopicMenu
                    topics={topicTree.filter(value => value.qcode.startsWith('mnidatatopics'))}
                    selectedTopics={selectedTopics}
                    onSelectionChange={s => {
                      console.log(s);
                      setSelectedTopics(s);
                    }}
                  />
                )}
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown className={'mt-1 d-container-block d-container-sm-inline flex-fill'}>
              <Dropdown.Toggle
                variant="outline-secondary"
                id="dropdown-basic"
                size="sm"
                className={`w-100  ${styles.controlButton}`}
              >
                Regions
              </Dropdown.Toggle>
              <Dropdown.Menu variant={'theme'} className={`border-secondary ${styles.topicList}`}>
                {topicTree && selectedRegions && (
                  <TopicMenu
                    topics={topicTree.filter(value => value.qcode.startsWith('mnidataregion'))}
                    selectedTopics={selectedRegions}
                    onSelectionChange={s => {
                      setSelectedRegions(s);
                    }}
                  />
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      )}
    </>
  );
}
