import { AuctionProduct } from '@/model/auctions/auction-product';
import { DataRelease } from '@/model/calendar/data-release';

export class TopicFilter {
  [key: string]: any;

  allSelected: boolean = true;

  constructor(topicFilter?: TopicFilter) {
    if (topicFilter) {
      for (const k in topicFilter) {
        this[k] = topicFilter[k];
      }
    }
  }

  allowRelease(dataRelease?: DataRelease): boolean {
    if (!dataRelease) return true;

    if (this.allSelected) return true;

    let allow = false;

    dataRelease.series.forEach(value => {
      value.topics.forEach(topic => {
        if (this[topic.qcode]) allow = true;
      });
    });

    return allow;
  }

  allowAuction(auctionProduct?: AuctionProduct): boolean {
    if (!auctionProduct) return true;

    if (this.allSelected) return true;

    let allow = false;

    auctionProduct.type.topics.forEach(topic => {
      if (this[topic.qcode]) allow = true;
    });

    return allow;
  }
}
