import { RouteObject } from 'react-router-dom';

import { NewsWidgetFactory } from '@/widgets/news-widget-factory';
import { WidgetFactoryWrapper } from '@/widgets/widget-router/widget-factory-wrapper';

export function convertWidgetFactoryToReactRoute(widget: NewsWidgetFactory): RouteObject[] {
  const routes = [
    {
      path: `${widget.path}/*`,
      element: (
        <div className={'p-2 vh-100'}>
          <WidgetFactoryWrapper factory={widget} />
        </div>
      ),
    },
  ];

  if (widget.internalPaths) {
    widget.internalPaths().forEach(p => {
      routes.push({
        path: p,
        element: (
          <div className={'p-2 vh-100'}>
            <WidgetFactoryWrapper factory={widget} url={p}/>
          </div>
        ),
      })
    })
  }

  return routes;
}
