import React from 'react';

import { AuctionProduct } from '@/model/auctions/auction-product';
import { AuctionProductId } from '@/model/auctions/auction-product-id';
import Round from '@/components/shared/round';

export default function AuctionRowEntry({ auctionProduct }: { auctionProduct: AuctionProduct }) {
  return (
    <React.Fragment>
      <td>
        <div className={'w-100 d-flex'}>
          <span className={'me-auto text-text-dark'}>
            <strong className={'text-muted'}>Auction:</strong>{' '}
            {auctionProduct.attributes.find(attribute => attribute.id?.toLowerCase() === 'display')
              ?.value || auctionProduct.display}
          </span>
        </div>
      </td>
      <td></td>
      <td>
        <span className={'text-muted'}> B/C</span>
      </td>
      <td>
        <strong>
          <Round
            value={auctionProduct.attributes.find(value => value.id === 'bidToCoverRatio')?.value}
            addTrailingZeros={true}
          />
        </strong>
      </td>
      <td colSpan={2} className={'small text-end'}>
        <div className={'small text-muted'}>{(auctionProduct.id as AuctionProductId).type}</div>
        <div className={'small text-muted'}>{(auctionProduct.id as AuctionProductId).value}</div>
      </td>
    </React.Fragment>
  );
}
