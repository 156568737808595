import {Article} from '@/model/article';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBookmark as bookmarkSolid} from '@fortawesome/free-solid-svg-icons';
import {faBookmark as bookmarkOutline} from '@fortawesome/free-regular-svg-icons';
import {opinionsApi} from '@/api';
import {useEffect, useState} from 'react';
import {analyticsService, messageService} from '@/services';
import {MessageServiceConstants} from '@/services/messaging/message-service-constants';
import {OpinionMap} from '@/model/opinions/opinion-map';
import useSubscription from '@/hooks/use-subscription';
import {AnalyticsEvent} from '@/services/analytics/analytics-event';

export default function BookmarkButton({
  article,
  providedOpinions,
}: {
  article: Article;
  providedOpinions?: OpinionMap;
}) {
  const [opinions, setOpinions] = useState<OpinionMap>(providedOpinions || {});
  const bookmark = async () => {
    if (isBookmarked()) {
      analyticsService.recordAnalyticsEvent(AnalyticsEvent.ARTICLE_UN_BOOKMARKED,{
        articleId:article.uri,
        articleTitle: article.headline
      })
      await opinionsApi.removeOpinion(article.uri, 'BOOKMARK');
    } else {
      analyticsService.recordAnalyticsEvent(AnalyticsEvent.ARTICLE_BOOKMARKED,{
        articleId:article.uri,
        articleTitle: article.headline
      })
      await opinionsApi.saveOpinion(article.uri, 'BOOKMARK');
    }

    opinionsApi.getOpinionsOfArticles(article.uri).then(value => {
      setOpinions(value);
      messageService.publish(MessageServiceConstants.BOOKMARKS_CHANGED, value);
    });
  };

  useSubscription<OpinionMap>(MessageServiceConstants.BOOKMARKS_CHANGED, message => {
    setOpinions({ ...opinions, ...message });
  });

  const isBookmarked = () => {
    return opinions[article.uri]?.includes('BOOKMARK');
  };

  useEffect(() => {
    if (!providedOpinions) opinionsApi.getOpinionsOfArticles(article.uri).then(setOpinions);
  }, [article]);

  useEffect(() => {
    if (providedOpinions) setOpinions(providedOpinions);
  }, [providedOpinions]);

  return (
    <button
      onClick={bookmark}
      style={{ fontFamily: 'Inter' }}
      className={`btn btn-sm btn-link link-warning pt-0 pb-0`}
    >
      {isBookmarked() && <FontAwesomeIcon icon={bookmarkSolid} />}
      {!isBookmarked() && <FontAwesomeIcon icon={bookmarkOutline} />}
    </button>
  );
}
