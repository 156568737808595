import { useLoaderData } from 'react-router-dom';
import { useContext } from 'react';
import {
  LocalWidgetRouterContext,
  LocalWidgetRouterState,
} from '@/widgets/widget-router/local-widget-router';

export default function useWidgetLoaderData() {
  const context = useContext<LocalWidgetRouterState>(LocalWidgetRouterContext);
  const d = useLoaderData();

  if (context.useLocal) {
    return context.loaderData;
  }

  return d;
}
