import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlugCircleCheck, faPlugCircleXmark } from '@fortawesome/free-solid-svg-icons';
import useTimer from '@/hooks/use-timer';
import useSubscription from '@/hooks/use-subscription';
import { messageService } from '@/services';
import { MessageServiceConstants } from '@/services/messaging/message-service-constants';
import { MessageConnectionType } from '@/services/messaging/message-connection-type';

export default function ConnectionIndicator() {
  let [connected, setConnected] = useState<boolean | null>(messageService.isConnected());
  let [connectionType, setConnectionType] = useState<MessageConnectionType>(
    messageService.getConnectionType(),
  );

  useTimer(
    5_000,
    () => {
      setConnected(messageService.isConnected());
      setConnectionType(messageService.getConnectionType());
    },
    false,
  );

  useSubscription(MessageServiceConstants.MESSAGE_CONNECTION_STATE, message => {
    setConnected(messageService.isConnected());
    setConnectionType(messageService.getConnectionType());
  });

  const connectionTypeClass = (type?: MessageConnectionType) => {
    if (connectionType === MessageConnectionType.STREAMING) return 'text-info';
    if (connectionType === MessageConnectionType.POLLING) return 'text-secondary';

    return 'text-success';
  };

  if (connected === null)
    return <FontAwesomeIcon className={'text-black'} icon={faPlugCircleCheck} />;

  return (
    <>
      {connected ? (
        <FontAwesomeIcon className={connectionTypeClass(connectionType)} icon={faPlugCircleCheck} />
      ) : (
        <FontAwesomeIcon className={'text-danger'} icon={faPlugCircleXmark} />
      )}
    </>
  );
}
