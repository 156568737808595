import { ConceptItem } from '@/model/concept-item';

export class ArticleUtil {
  static hasGenre(article: Article, genre: string) {
    return (
      article.genre.filter(value => value.code.toLowerCase() === genre.toLowerCase()).length > 0
    );
  }
}

export interface Article {
  uri: string;
  type: string;
  representationtype: string;
  version: string;
  firstcreated: string;
  versioncreated: string;
  pubstatus: string;
  language: string;
  mimetype: string;
  copyrightholder: string;
  copyrightnotice: string;
  headline: string;
  body_text: string;
  body_xhtml: string;
  person: any[];
  byline: string;
  genre: ConceptItem[];
  subject: ConceptItem[];
  summary: string;
  generatedSummary: boolean;
  pdfs: any[];
  image?: any;

  keywords: string[];
}
