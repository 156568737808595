import { ConceptItem } from '@/model/concept-item';

export default function GenreBadge({ genre }: { genre: ConceptItem }) {
  var theme = 'bg-secondary';

  if (genre.code === 'BULLET') theme = 'bg-tertiary text-base-light';
  if (genre.code === 'STORY') theme = 'bg-success text-base-light';
  if (genre.code === 'HEADLINE') theme = 'bg-warning text-base-light';
  if (genre.code === 'NEWSLETTER') theme = 'bg-error text-base-light';

  return <span className={`badge ${theme} `}>{genre.name}</span>;
}
