import { LocalPreferences } from '@/model/preferences/local-preferences';
import { LocalStorageService } from '@/services/storage/local-storage-service';
import { MessageConnectionType } from '@/services/messaging/message-connection-type';

const PREFERENCES_KEY = 'userPreferences';

const storageService = new LocalStorageService();

export const defaultPreferences = (): LocalPreferences => ({
  timezoneType: 'local',
  contrastingColors: 'orange-blue',
  showFlags: true,
  messageConnectionType: MessageConnectionType.WEBSOCKET,
  theme: 'dark',
  lastUpdated: new Date(),
  timezone: 'America/New_York',
});

async function getLocalPreferences(): Promise<LocalPreferences> {
  let preferences = await storageService.getValue<LocalPreferences>(PREFERENCES_KEY);

  if (!preferences) {
    preferences = defaultPreferences();
    await saveLocalPreferences(preferences);
  }

  return preferences;
}

async function saveLocalPreferences(localPreferences: LocalPreferences): Promise<LocalPreferences> {
  localPreferences.lastUpdated = new Date();
  await storageService.saveValue(PREFERENCES_KEY, localPreferences);

  console.log('PREFERENCES', localPreferences);

  return getLocalPreferences();
}

async function updateLocalPreferences(
  callback: (localPreferences: LocalPreferences) => Promise<LocalPreferences>,
): Promise<LocalPreferences> {
  let preferences = await getLocalPreferences();

  preferences = await callback(preferences);

  return saveLocalPreferences(preferences);
}

export default {
  updateLocalPreferences,
  getLocalPreferences,
};
