import { MNIDashboardWidgetPreferences } from '@/model/preferences/myMNIPreferences';
import { Layouts } from 'react-grid-layout';
import { IconProp } from '@fortawesome/fontawesome';
import {
  faArrowUpRightDots,
  faGlobe,
  faMagnifyingGlassChart,
  faOilWell,
} from '@fortawesome/free-solid-svg-icons';
import { faCreditCard, faHandshake } from '@fortawesome/free-regular-svg-icons';

export interface SuggestedLayout {
  name: string;
  description: string;
  layouts: Layouts;
  order: number;
  widgetPreferences: MNIDashboardWidgetPreferences;
  icon: any;
}

const suggestedLayouts: SuggestedLayout[] = [
  {
    order: 4,
    icon: faOilWell,
    name: 'Commodities Focused',
    description: 'Everything about Energy, all the time.',
    layouts: {
      lg: [
        {
          w: 4,
          h: 4,
          x: 4,
          y: 4,
          i: '/pinned-series/mni-section:2255207223',
          moved: false,
          static: false,
        },
        {
          w: 4,
          h: 4,
          x: 4,
          y: 0,
          i: '/pinned-series/mni-section:2148748890',
          moved: false,
          static: false,
        },
        { w: 4, h: 8, x: 12, y: 0, i: '/calendar', moved: false, static: false },
        {
          w: 4,
          h: 8,
          x: 8,
          y: 0,
          i: '/bullets/energy-bullets',
          moved: false,
          static: false,
        },
        { w: 4, h: 8, x: 0, y: 0, i: '/bullets/all-bullets', moved: false, static: false },
      ],
      md: [
        {
          h: 4,
          i: '/pinned-series/mni-section:2255207223',
          w: 4,
          x: 8,
          y: 4,
          moved: false,
          static: false,
        },
        {
          h: 4,
          i: '/pinned-series/mni-section:2148748890',
          w: 4,
          x: 4,
          y: 4,
          moved: false,
          static: false,
        },
        {
          h: 4,
          i: '/calendar',
          w: 4,
          x: 8,
          y: 0,
          moved: false,
          static: false,
        },
        {
          h: 4,
          i: '/bullets/energy-bullets',
          w: 4,
          x: 4,
          y: 0,
          moved: false,
          static: false,
        },
        {
          h: 8,
          i: '/bullets/all-bullets',
          w: 4,
          x: 0,
          y: 0,
          moved: false,
          static: false,
        },
      ],
      sm: [
        {
          h: 4,
          i: '/pinned-series/mni-section:2255207223',
          w: 4,
          x: 4,
          y: 4,
          moved: false,
          static: false,
        },
        {
          h: 4,
          i: '/pinned-series/mni-section:2148748890',
          w: 4,
          x: 0,
          y: 8,
          moved: false,
          static: false,
        },
        {
          h: 4,
          i: '/calendar',
          w: 4,
          x: 4,
          y: 8,
          moved: false,
          static: false,
        },
        {
          h: 4,
          i: '/bullets/energy-bullets',
          w: 4,
          x: 4,
          y: 0,
          moved: false,
          static: false,
        },
        {
          h: 8,
          i: '/bullets/all-bullets',
          w: 4,
          x: 0,
          y: 0,
          moved: false,
          static: false,
        },
      ],
    },
    widgetPreferences: {
      '/calendar': {},
      '/bullets/all-bullets': {
        includedSections: [
          'fx-bullets',
          'fi-bullets',
          'em-bullets',
          'all-credit-bullets',
          'oil-bullets',
          'power-bot',
          'gas-bullets',
        ],
      },
      '/bullets/energy-bullets': {
        includedSections: ['oil-bullets', 'power-bot', 'gas-bullets'],
      },
      '/pinned-series/mni-section:2148748890': {
        color: 'note-orange',
        title: 'Commodities Weekly Analysis',
      },
      '/pinned-series/mni-section:2255207223': {
        color: 'note-blue',
        title: 'NatGas & LNG Analysis',
      },
    },
  },
  {
    order: 5,
    icon: faCreditCard,
    name: 'Credit Focused',
    description: 'All of the credit markets together!',
    layouts: {
      lg: [
        {
          h: 5,
          i: '/pinned-series/mni-section:2243823041',
          w: 4,
          x: 12,
          y: 0,
          moved: false,
          static: false,
        },
        {
          h: 3,
          i: '/events/fomc-meeting-calendar',
          w: 4,
          x: 12,
          y: 5,
          moved: false,
          static: false,
        },
        {
          h: 8,
          i: '/calendar',
          w: 5,
          x: 7,
          y: 0,
          moved: false,
          static: false,
        },
        {
          h: 8,
          i: '/bullets/credit-bullets',
          w: 7,
          x: 0,
          y: 0,
          moved: false,
          static: false,
        },
      ],
      md: [
        {
          h: 4,
          i: '/pinned-series/mni-section:2243823041',
          w: 6,
          x: 6,
          y: 0,
          moved: false,
          static: false,
        },
        {
          h: 3,
          i: '/events/fomc-meeting-calendar',
          w: 6,
          x: 0,
          y: 5,
          moved: false,
          static: false,
        },
        {
          h: 4,
          i: '/calendar',
          w: 6,
          x: 6,
          y: 4,
          moved: false,
          static: false,
        },
        {
          h: 5,
          i: '/bullets/credit-bullets',
          w: 6,
          x: 0,
          y: 0,
          moved: false,
          static: false,
        },
      ],
      sm: [
        {
          h: 4,
          i: '/pinned-series/mni-section:2243823041',
          w: 4,
          x: 4,
          y: 0,
          moved: false,
          static: false,
        },
        {
          h: 4,
          i: '/events/fomc-meeting-calendar',
          w: 4,
          x: 0,
          y: 5,
          moved: false,
          static: false,
        },
        {
          h: 5,
          i: '/calendar',
          w: 4,
          x: 4,
          y: 4,
          moved: false,
          static: false,
        },
        {
          h: 5,
          i: '/bullets/credit-bullets',
          w: 4,
          x: 0,
          y: 0,
          moved: false,
          static: false,
        },
      ],
    },
    widgetPreferences: {
      '/calendar': {},
      '/bullets/credit-bullets': {
        includedSections: ['eu-credit-bullets', 'us-credit-bullets', 'em-credit-bullets'],
      },
      '/events/fomc-meeting-calendar': {},
      '/pinned-series/mni-section:2243823041': {
        title: 'Credit Analysis',
      },
    },
  },
  {
    order: 3,
    name: 'Emerging Markets',
    icon: faArrowUpRightDots,
    description: 'EM Markets, EM Credit and EM Policy, everything in one place!',
    layouts: {
      lg: [
        {
          h: 4,
          i: '/pinned-series/mni-section:538093858',
          w: 4,
          x: 12,
          y: 0,
          moved: false,
          static: false,
        },
        {
          h: 4,
          i: '/calendar',
          w: 4,
          x: 12,
          y: 4,
          moved: false,
          static: false,
        },
        {
          h: 8,
          i: '/bullets/mainwire',
          w: 4,
          x: 8,
          y: 0,
          moved: false,
          static: false,
        },
        {
          h: 8,
          i: '/bullets/em-bullets',
          w: 4,
          x: 0,
          y: 0,
          moved: false,
          static: false,
        },
        {
          h: 8,
          i: '/bullets/credit-bullets',
          w: 4,
          x: 4,
          y: 0,
          moved: false,
          static: false,
        },
      ],
      md: [
        {
          h: 4,
          i: '/pinned-series/mni-section:538093858',
          w: 4,
          x: 4,
          y: 4,
          moved: false,
          static: false,
        },
        {
          h: 4,
          i: '/calendar',
          w: 4,
          x: 8,
          y: 4,
          moved: false,
          static: false,
        },
        {
          h: 4,
          i: '/bullets/mainwire',
          w: 4,
          x: 8,
          y: 0,
          moved: false,
          static: false,
        },
        {
          h: 8,
          i: '/bullets/em-bullets',
          w: 4,
          x: 0,
          y: 0,
          moved: false,
          static: false,
        },
        {
          h: 4,
          i: '/bullets/credit-bullets',
          w: 4,
          x: 4,
          y: 0,
          moved: false,
          static: false,
        },
      ],
      sm: [
        {
          h: 4,
          i: '/pinned-series/mni-section:538093858',
          w: 4,
          x: 0,
          y: 8,
          moved: false,
          static: false,
        },
        {
          h: 4,
          i: '/calendar',
          w: 4,
          x: 4,
          y: 8,
          moved: false,
          static: false,
        },
        {
          h: 4,
          i: '/bullets/mainwire',
          w: 4,
          x: 4,
          y: 0,
          moved: false,
          static: false,
        },
        {
          h: 8,
          i: '/bullets/em-bullets',
          w: 4,
          x: 0,
          y: 0,
          moved: false,
          static: false,
        },
        {
          h: 4,
          i: '/bullets/credit-bullets',
          w: 4,
          x: 4,
          y: 4,
          moved: false,
          static: false,
        },
      ],
    },
    widgetPreferences: {
      '/calendar': {},
      '/bullets/mainwire': {
        title: 'EM Policy',
        includedSections: ['em-mainwire'],
      },
      '/bullets/em-bullets': {
        includedSections: ['em-bullets'],
      },
      '/bullets/credit-bullets': {
        title: 'EM Credit',
        includedSections: ['em-credit-bullets'],
      },
      '/pinned-series/mni-section:538093858': {
        color: 'note-blue',
        title: 'LatAm Bank - Preview',
      },
    },
  },
  {
    order: 2,
    name: 'FX/FI Markets',
    icon: faMagnifyingGlassChart,
    description: 'Everything about G10 markets together!',
    layouts: {
      lg: [
        {
          h: 4,
          i: '/pinned-series/mni-section:600166007',
          w: 4,
          x: 12,
          y: 0,
          moved: false,
          static: false,
        },
        {
          h: 4,
          i: '/pinned-series/mni-section:504988115',
          w: 4,
          x: 12,
          y: 4,
          moved: false,
          static: false,
        },
        {
          h: 4,
          i: '/calendar',
          w: 6,
          x: 6,
          y: 4,
          moved: false,
          static: false,
        },
        {
          h: 4,
          i: '/bullets/mainwire',
          w: 6,
          x: 6,
          y: 0,
          moved: false,
          static: false,
        },
        {
          h: 8,
          i: '/bullets/all-bullets',
          w: 6,
          x: 0,
          y: 0,
          moved: false,
          static: false,
        },
      ],
      md: [
        {
          h: 4,
          i: '/pinned-series/mni-section:600166007',
          w: 3,
          x: 3,
          y: 4,
          moved: false,
          static: false,
        },
        {
          h: 4,
          i: '/pinned-series/mni-section:504988115',
          w: 3,
          x: 0,
          y: 4,
          moved: false,
          static: false,
        },
        {
          h: 4,
          i: '/calendar',
          w: 6,
          x: 6,
          y: 4,
          moved: false,
          static: false,
        },
        {
          h: 4,
          i: '/bullets/mainwire',
          w: 6,
          x: 6,
          y: 0,
          moved: false,
          static: false,
        },
        {
          h: 4,
          i: '/bullets/all-bullets',
          w: 6,
          x: 0,
          y: 0,
          moved: false,
          static: false,
        },
      ],
      sm: [
        {
          h: 4,
          i: '/pinned-series/mni-section:600166007',
          w: 4,
          x: 4,
          y: 4,
          moved: false,
          static: false,
        },
        {
          h: 4,
          i: '/pinned-series/mni-section:504988115',
          w: 4,
          x: 0,
          y: 4,
          moved: false,
          static: false,
        },
        {
          h: 4,
          i: '/calendar',
          w: 8,
          x: 0,
          y: 8,
          moved: false,
          static: false,
        },
        {
          h: 4,
          i: '/bullets/mainwire',
          w: 4,
          x: 4,
          y: 0,
          moved: false,
          static: false,
        },
        {
          h: 4,
          i: '/bullets/all-bullets',
          w: 4,
          x: 0,
          y: 0,
          moved: false,
          static: false,
        },
      ],
    },
    widgetPreferences: {
      '/calendar': {},
      '/bullets/mainwire': {
        includedSections: ['mainwire', 'em-mainwire'],
      },
      '/bullets/all-bullets': {
        includedSections: [
          'fx-bullets',
          'fi-bullets',
          'em-bullets',
          'all-credit-bullets',
          'oil-bullets',
          'power-bot',
          'gas-bullets',
        ],
      },
      '/pinned-series/mni-section:504988115': {
        title: 'Fed - Preview',
      },
      '/pinned-series/mni-section:600166007': {
        title: 'Political Risk - US Daily Brief',
      },
    },
  },
  {
    order: 1,
    name: 'Global Markets and Policy',
    icon: faGlobe,
    description: 'You care about everything? This is your space, to keep yourself up to date!',
    layouts: {
      lg: [
        {
          h: 3,
          i: '/pinned-series/mni-section:504988115',
          w: 5,
          x: 11,
          y: 5,
          moved: false,
          static: false,
        },
        {
          h: 5,
          i: '/calendar',
          w: 5,
          x: 11,
          y: 0,
          moved: false,
          static: false,
        },
        {
          h: 8,
          i: '/bullets/mainwire',
          w: 5,
          x: 6,
          y: 0,
          moved: false,
          static: false,
        },
        {
          h: 8,
          i: '/bullets/all-bullets',
          w: 6,
          x: 0,
          y: 0,
          moved: false,
          static: false,
        },
      ],
      md: [
        {
          h: 4,
          i: '/pinned-series/mni-section:504988115',
          w: 4,
          x: 8,
          y: 4,
          moved: false,
          static: false,
        },
        {
          h: 4,
          i: '/calendar',
          w: 4,
          x: 8,
          y: 0,
          moved: false,
          static: false,
        },
        {
          h: 8,
          i: '/bullets/mainwire',
          w: 4,
          x: 4,
          y: 0,
          moved: false,
          static: false,
        },
        {
          h: 8,
          i: '/bullets/all-bullets',
          w: 4,
          x: 0,
          y: 0,
          moved: false,
          static: false,
        },
      ],
      sm: [
        {
          h: 4,
          i: '/pinned-series/mni-section:504988115',
          w: 4,
          x: 4,
          y: 4,
          moved: false,
          static: false,
        },
        {
          h: 4,
          i: '/calendar',
          w: 4,
          x: 0,
          y: 4,
          moved: false,
          static: false,
        },
        {
          h: 4,
          i: '/bullets/mainwire',
          w: 4,
          x: 4,
          y: 0,
          moved: false,
          static: false,
        },
        {
          h: 4,
          i: '/bullets/all-bullets',
          w: 4,
          x: 0,
          y: 0,
          moved: false,
          static: false,
        },
      ],
    },
    widgetPreferences: {
      '/calendar': {},
      '/bullets/mainwire': {
        includedSections: ['mainwire', 'em-mainwire'],
      },
      '/bullets/all-bullets': {
        includedSections: [
          'fx-bullets',
          'fi-bullets',
          'em-bullets',
          'all-credit-bullets',
          'oil-bullets',
          'power-bot',
          'gas-bullets',
        ],
      },
      '/pinned-series/mni-section:504988115': {
        title: 'Fed - Preview',
      },
    },
  },
  {
    order: 6,
    name: 'Corporate Dashboard',
    icon: faHandshake,
    description: 'Are you just interested in Global Macro? This is your suggested dashboard!',
    layouts: {
      lg: [
        {
          h: 4,
          i: '/pinned-series/mni-section:551192114',
          w: 4,
          x: 12,
          y: 0,
          moved: false,
          static: false,
        },
        {
          h: 4,
          i: '/pinned-series/mni-section:2257094548',
          w: 4,
          x: 12,
          y: 4,
          moved: false,
          static: false,
        },
        {
          h: 8,
          i: '/calendar',
          w: 5,
          x: 7,
          y: 0,
          moved: false,
          static: false,
        },
        {
          h: 8,
          i: '/bullets/gm-bullets',
          w: 7,
          x: 0,
          y: 0,
          moved: false,
          static: false,
        },
      ],
      md: [
        {
          h: 4,
          i: '/pinned-series/mni-section:551192114',
          w: 6,
          x: 6,
          y: 0,
          moved: false,
          static: false,
        },
        {
          h: 5,
          i: '/pinned-series/mni-section:2257094548',
          w: 6,
          x: 6,
          y: 4,
          moved: false,
          static: false,
        },
        {
          h: 3,
          i: '/calendar',
          w: 6,
          x: 0,
          y: 6,
          moved: false,
          static: false,
        },
        {
          h: 6,
          i: '/bullets/gm-bullets',
          w: 6,
          x: 0,
          y: 0,
          moved: false,
          static: false,
        },
      ],
      sm: [
        {
          h: 5,
          i: '/pinned-series/mni-section:551192114',
          w: 4,
          x: 4,
          y: 0,
          moved: false,
          static: false,
        },
        {
          h: 4,
          i: '/pinned-series/mni-section:2257094548',
          w: 4,
          x: 4,
          y: 5,
          moved: false,
          static: false,
        },
        {
          h: 3,
          i: '/calendar',
          w: 4,
          x: 0,
          y: 6,
          moved: false,
          static: false,
        },
        {
          h: 6,
          i: '/bullets/gm-bullets',
          w: 4,
          x: 0,
          y: 0,
          moved: false,
          static: false,
        },
      ],
    },
    widgetPreferences: {
      '/calendar': {},
      '/bullets/gm-bullets': {
        includedSections: ['dm-gm-bullets', 'em-gm-bullets'],
      },
      '/pinned-series/mni-section:551192114': {
        title: 'Inflation Insight',
      },
      '/pinned-series/mni-section:2257094548': {
        title: 'Global Macro Weekly',
      },
    },
  },
];

export default suggestedLayouts;
