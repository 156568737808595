import React, { useEffect, useRef, useState } from 'react';
import { Overlay, Tooltip } from 'react-bootstrap';

export default function ClipboardButton({
  children,
  text,
}: React.PropsWithChildren<{ text: string }>) {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  const copyTextToClipboard = async (text: string) => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  };

  const copy = () => {
    copyTextToClipboard(text).then(value => {
      setShow(true);
    });
  };

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setShow(false);
      }, 500);
    }
  }, [show]);

  return (
    <>
      <span ref={target} onClick={copy}>
        {children}
      </span>
      <Overlay target={target.current} show={show} placement={'bottom-end'}>
        <Tooltip id="overlay-example" color={'white'}>
          <span className={'text-light bg-light'}>copied link to clipboard</span>
        </Tooltip>
      </Overlay>
    </>
  );
}
