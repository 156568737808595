export default function CountryFlag({
  country,
  width,
  height,
}: {
  country: string;
  width?: string | number;
  height?: string | number;
}) {
  country = country.toLowerCase();

  if (country === 'uk') country = 'gb';

  return (
    <>
      <img
        src={`https://flagcdn.com/${country}.svg`}
        height={height}
        width={width}
        alt={country}
        className={'flag-image'}
      />
      <span className={'flag-text text-muted fw-bold'}>{country.toUpperCase()}</span>
    </>
  );
}
