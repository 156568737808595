import { ChangeEvent, useEffect, useState } from 'react';
import { faPlugCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { ContrastingColors, ThemeValues } from '@/model/preferences/local-preferences';
import Menu from '@/components/shared/menu';
import DataDownload from '@/components/settings/components/data-download';
import { localPreferencesService, messageService, themeService } from '@/services';
import { MessageConnectionType } from '@/services/messaging/message-connection-type';

declare namespace Intl {
  type Key = 'calendar' | 'collation' | 'currency' | 'numberingSystem' | 'timeZone' | 'unit';

  function supportedValuesOf(input: Key): string[];
}

export default function SettingsMenu() {
  const navigate = useNavigate();

  const [connectionType, setConnectionType] = useState(messageService.getConnectionType());
  const [timezone, setTimezone] = useState<string>(dayjs.tz.guess());
  const timeZones = Intl.supportedValuesOf('timeZone');
  const [timezoneType, setTimezoneType] = useState<'local' | 'explicit'>('local');
  const [contrastingColors, setContrastingColors] = useState<ContrastingColors>('orange-blue');
  const [theme, setTheme] = useState<ThemeValues>('dark');
  const [showFlags, setShowFlags] = useState<boolean>(true);

  useEffect(() => {
    localPreferencesService.getLocalPreferences().then(value => {
      if (value.timezone) setTimezone(value.timezone);

      if (value.timezoneType === 'explicit') setTimezoneType('explicit');

      if (value.contrastingColors) setContrastingColors(value.contrastingColors);

      if (value.theme) setTheme(value.theme);

      if (value.showFlags || value.showFlags === false) setShowFlags(value.showFlags);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelectConnectionType = async (event: ChangeEvent<HTMLSelectElement>) => {
    let value = (event.target as HTMLSelectElement).value as MessageConnectionType;
    if (value === connectionType) return;

    console.log('Changing message transport to: ' + value + ' from ' + connectionType);

    let preferences = await localPreferencesService.updateLocalPreferences(
      async localPreferences => {
        localPreferences.messageConnectionType = value;

        return localPreferences;
      },
    );

    setConnectionType(preferences.messageConnectionType);
    messageService.setConnectionType(preferences.messageConnectionType);
    await messageService.reactivateConnection();
  };

  const onSelectTimezone = (event: ChangeEvent<HTMLSelectElement>) => {
    let value = (event.target as HTMLSelectElement).value as string;

    localPreferencesService
      .updateLocalPreferences(async localPreferences => {
        localPreferences.timezone = value;

        return localPreferences;
      })
      .then(localPreferences => {
        dayjs.tz.setDefault(value);
        setTimezone(value);
      });
  };

  const onColorSelect = (value: ContrastingColors) => {
    // let value = (event.target as HTMLSelectElement).value as 'orangeBlue' | 'redGreen' | 'purpleYellow';

    localPreferencesService
      .updateLocalPreferences(async localPreferences => {
        localPreferences.contrastingColors = value;

        return localPreferences;
      })
      .then(localPreferences => {
        setContrastingColors(value);
        themeService.setThemeBasedOnUserPreferences(localPreferences);
      });
  };

  const onThemeSelect = (value: ThemeValues) => {
    // let value = (event.target as HTMLSelectElement).value as 'orangeBlue' | 'redGreen' | 'purpleYellow';

    localPreferencesService
      .updateLocalPreferences(async localPreferences => {
        localPreferences.theme = value;

        return localPreferences;
      })
      .then(localPreferences => {
        setTheme(value);
        themeService.setThemeBasedOnUserPreferences(localPreferences);
      });
  };

  const onShowFlagsSelect = (value: boolean) => {
    // let value = (event.target as HTMLSelectElement).value as 'orangeBlue' | 'redGreen' | 'purpleYellow';

    localPreferencesService
      .updateLocalPreferences(async localPreferences => {
        localPreferences.showFlags = value;

        return localPreferences;
      })
      .then(localPreferences => {
        setShowFlags(value);
        themeService.setThemeBasedOnUserPreferences(localPreferences);
      });
  };

  const onTimezoneTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value as 'local' | 'explicit';

    localPreferencesService
      .updateLocalPreferences(async localPreferences => {
        localPreferences.timezoneType = value;

        if (value === 'local') localPreferences.timezone = dayjs.tz.guess();

        return localPreferences;
      })
      .then(localPreferences => {
        setTimezoneType(localPreferences.timezoneType);

        if (localPreferences.timezoneType === 'explicit') {
          setTimezone(localPreferences.timezone);
          dayjs.tz.setDefault(localPreferences.timezone);
        } else {
          setTimezone(dayjs.tz.guess());
          dayjs.tz.setDefault();
        }
      });
  };
  const radios = [
    { name: 'Local Timezone', value: 'local' },
    { name: 'Explicit Timezone', value: 'explicit' },
  ];

  return (
    <div className="container-element">
      <div className={'pt-1 pb-1 d-flex-hide-in-dock container-responsive-text'}>
        <span className={'ms-auto '}>
          {' '}
          <button
            style={{ fontFamily: 'Inter' }}
            className={'btn btn-outline-secondary text-secondary btn-menu btn-sm '}
            onClick={event => navigate(-1)}
          >
            Back{' '}
          </button>
        </span>
        <span className={'ms-1'}>
          <Menu />
        </span>
      </div>
      <div className={'m-4 container-responsive-text'}>
        <div className="mb-3">
          <h6>Real-Time Connection Technology</h6>
          <select
            value={connectionType}
            onChange={event => onSelectConnectionType(event)}
            className={'form-control'}
          >
            <option value={MessageConnectionType.WEBSOCKET}>Websockets (Best)</option>
            <option value={MessageConnectionType.STREAMING}>HTTP Streaming (Better)</option>
            <option value={MessageConnectionType.POLLING}>HTTP Polling (Good)</option>
          </select>
          <div id="emailHelp" className="form-text mt-2">
            We offer 3 connection options:
            <ul>
              <li>
                <FontAwesomeIcon className={'text-success me-1'} icon={faPlugCircleCheck} />
                Websockets: this provides the lowest latency possible, but may not work with some
                firewalls and network hardware.
              </li>
              <li>
                <FontAwesomeIcon className={'text-info me-1'} icon={faPlugCircleCheck} />
                HTTP Streaming: this provides good latency, and low-bandwidth usage.
              </li>
              <li>
                <FontAwesomeIcon className={'text-secondary me-1'} icon={faPlugCircleCheck} />
                HTTP Polling: this works with older browsers.
              </li>
            </ul>
          </div>
        </div>

        <div className="mb-3">
          <h6>Timezone</h6>

          {radios.map(value => (
            <div className="form-check" key={value.name}>
              <input
                className="form-check-input"
                type="radio"
                name="timezoneType"
                value={value.value}
                onChange={onTimezoneTypeChange}
                checked={timezoneType === value.value}
              />
              <label className="form-check-label" htmlFor="flexRadioDefault1">
                {value.name}
              </label>
            </div>
          ))}

          <select
            value={timezone}
            onChange={event => onSelectTimezone(event)}
            className={'form-control'}
            disabled={timezoneType !== 'explicit'}
          >
            {timeZones.map(value => (
              <option>{value}</option>
            ))}
          </select>
          <div id="emailHelp" className="form-text ">
            Timezone for display.
          </div>
        </div>

        <div className="mb-3">
          <h6>Appearance</h6>
          <label htmlFor="exampleInputEmail1" className="form-label">
            Theme
          </label>

          <select
            value={theme}
            className={'form-control'}
            onChange={event => onThemeSelect(event.target.value as ThemeValues)}
          >
            <option value={'dark'}>Dark</option>
            <option value={'light'}>Light</option>
          </select>
          <div id="emailHelp" className="form-text mb-2 ">
            Application theme.
          </div>

          <label htmlFor="exampleInputEmail1" className="form-label">
            Contrasting Colors
          </label>

          <select
            value={contrastingColors}
            className={'form-control'}
            onChange={event => onColorSelect(event.target.value as ContrastingColors)}
          >
            <option value={'orange-blue'}>Orange / Blue</option>
            <option value={'red-green'}>Red / Green</option>
            <option value={'yellow-purple'}>Yellow / Purple</option>
          </select>
          <div id="emailHelp" className="form-text mb-2 ">
            Colors for calculated differences.
          </div>

          <label htmlFor="exampleInputEmail1" className="form-label">
            Show Flags
          </label>

          <select
            value={`${showFlags}`}
            className={'form-control'}
            onChange={event => onShowFlagsSelect(event.target.value === 'true')}
          >
            <option value={'true'}>Yes, show flag images</option>
            <option value={'false'}>No, just show country abbreviation</option>
          </select>
          <div id="emailHelp" className="form-text ">
            Whether to show flags or just country names.
          </div>
        </div>
        <div className="mb-3">
          <h6>Calendar Data Download</h6>

          <DataDownload />
        </div>
      </div>
    </div>
  );
}
