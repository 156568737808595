import { useState } from 'react';
import { Link } from 'react-router-dom';
import useTimer from '@/hooks/use-timer';
import useSubscription from '@/hooks/use-subscription';
import { messageService } from '@/services';
import { MessageServiceConstants } from '@/services/messaging/message-service-constants';

export default function ConnectionWarning() {
  const [date, setDate] = useState<Date>(messageService.lastSuccessfulConnectionTime());

  useTimer(5_000, () => {
    setDate(messageService.lastSuccessfulConnectionTime());
  });

  useSubscription(MessageServiceConstants.MESSAGE_CONNECTION_STATE, message => {
    setDate(messageService.lastSuccessfulConnectionTime());
  });

  if (new Date().getTime() > date.getTime() + 30_000)
    return (
      <div className={'alert alert-warning'}>
        <h5>Warning</h5>
        <p>
          You are not connected to our real-time services. Try{' '}
          <Link to={'/settings'}>visiting our settings menu </Link>
          for additional connectivity options.
        </p>
      </div>
    );

  return <></>;
}
