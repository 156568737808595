import { StompSubscription } from '@stomp/stompjs';

import { ClientSubscription } from '@/services/messaging/client-subscription';

export class Subscription {
  constructor(public readonly destination: string) {}
  clientSubscriptions: ClientSubscription[] = []; // {[key: string]: (message:any, headers:any) => void} = {}

  stompSubscription?: StompSubscription;

  get isLocal() {
    return /\/local\/.*/.test(this.destination);
  }

  publish(message: any, headers: any) {
    for (let clientSubscription of this.clientSubscriptions) {
      try {
        console.warn('Forwarding', clientSubscription, message, headers);
        clientSubscription.onMessage(message, headers);
      } catch (e) {
        console.error('Error handling message', e);
      }
    }
  }
}
