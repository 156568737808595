import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCirclePlus, faCircleXmark, faMoon, faSun} from '@fortawesome/free-solid-svg-icons';
import { WidgetContainer } from '@/widgets/widget-container';
import { WidgetContainerContext } from '@/widgets/widget-container/widget-container-context';
import {analyticsService} from '@/services';
import {AnalyticsEvent} from '@/services/analytics/analytics-event';

interface Timezone {
  code: string;
  name: string;
}

interface ClockPreferences {
  selectedTimezones: string[];
}

export default function Clock() {
  const widgetContainer = useContext<WidgetContainer>(WidgetContainerContext);
  const [time, setTime] = useState<number>(Date.now());
  const [selected, setSelected] = useState<string[]>(
    widgetContainer.getPreferences<ClockPreferences>()?.selectedTimezones || [],
  );

  const [selectedOption, setSelectedOption] = useState<string>();

  const timezones: Timezone[] = [
    { code: 'America/New_York', name: 'New York' },
    { code: 'America/Chicago', name: 'Chicago' },
    { code: 'Europe/London', name: 'London' },
    { code: 'Europe/Berlin', name: 'Berlin' },
    { code: 'Asia/Shanghai', name: 'Beijing' },
    { code: 'Asia/Tokyo', name: 'Tokyo' },
    { code: 'America/Los_Angeles',name:'Los Angeles'},
    { code: 'America/Sao_Paulo', name: 'Sao Paulo'},
    { code: 'Australia/Sydney', name: 'Sydney'},
    { code: 'Asia/Singapore',name: 'Singapore'},
    { code: 'Asia/Dubai', name:'Dubai'},
    { code: 'local', name: 'Local' },
  ];

  const remainingOptions = timezones.filter(
    value => value.code !== 'local' && !selected.includes(value.code),
  );

  useEffect(() => {
    setSelectedOption(undefined);


      widgetContainer.setPreferences<ClockPreferences>({
        selectedTimezones: selected,
      });

  }, [selected]);

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 10_000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const TimeZoneDisplay = ({ code }: { code: string }) => {
    const value = timezones.find(tz => tz.code == code);

    if (!value) return <></>;

    const date = value.code === 'local' ? dayjs(time) : dayjs(time).tz(value.code);

    let night = false;

    if (date.get('hours') > 17 || date.get('hours') < 8) night = true;

    return (
      <div key={value.code} className={'border-bottom mb-2 pb-2'}>
        <div className={`d-flex align-items-center ${night && 'text-info-emphasis'}`}>
          <span style={{width:'1em'}}>
            {night ? (
              <div className={'text-info'}>
                <FontAwesomeIcon icon={faMoon} />
              </div>
            ) : (
              <div className={'text-warning-emphasis'}>
                <FontAwesomeIcon icon={faSun} />
              </div>
            )}
          </span>

          <div className={'ms-2 fs-3'}> {value.name} </div>

          <div className={' ms-auto font-monospace  fs-3'}> {date.format('h:mm a')}</div>
          <span>
            <Button
              variant={''}
              size={'sm'}
              className={'link-danger'}
              onClick={() => {
                setSelected(selected.filter(value => value != code));
                analyticsService.recordAnalyticsEvent(AnalyticsEvent.WORLD_CLOCK_LOCATION_REMOVED, {
                  location:value
                })
              }}
            >
               <FontAwesomeIcon icon={faCircleXmark} className={value.code === 'local'?'opacity-0':''} />
            </Button>{' '}
          </span>
        </div>
        <div className={'d-flex justify-content-between'}>
          <span></span>
        </div>
      </div>
    );
  };

  return (
    <div className={'container-element h-100  overflow-auto'}>
      <div className={'container-responsive-text  pt-2'}>
        <TimeZoneDisplay code={'local'} />
        {selected.map(value => (
          <TimeZoneDisplay code={value} />
        ))}
        {remainingOptions.length !== 0 && (
          <div>
            <div className={'input-group'}>
              <select
                className={'form-select form-select-sm'}
                onChange={event => setSelectedOption(event.target.value)}
              >
                <option>-- add new --</option>
                {remainingOptions.map(value => (
                  <option key={'o-' + value.code} value={value.code}>
                    {value.name}
                  </option>
                ))}
              </select>
              <Button
                variant={'secondary'}
                size={'sm'}
                disabled={!selectedOption}
                onClick={() => {
                  selectedOption && setSelected([...selected, selectedOption])
                  analyticsService.recordAnalyticsEvent(AnalyticsEvent.WORLD_CLOCK_LOCATION_ADDED, {
                    location:selectedOption
                  })
                }}
              >
                <FontAwesomeIcon icon={faCirclePlus} />
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
