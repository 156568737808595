import { ReducerState, useContext, useEffect, useReducer, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  MNIDashboard,
  MNIDashboardWidgetPreferences,
  MyMNIPreferences,
} from '@/model/preferences/myMNIPreferences';
import { Context } from '@/App';

import {
  faPlay,
  faRightLeft,
  faSearch,
  faTowerCell,
} from '@fortawesome/free-solid-svg-icons';

import { Layouts } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { useSearchParams } from 'react-router-dom';
import DashboardDeleteModal from '@/components/dashboard/dashboard-delete-modal';
import DashboardCreateModal from '@/components/dashboard/dashboard-create-modal';
import DashboardContent from '@/components/dashboard/dashboard-content';
import { preferencesApi, shareApi } from '@/api';
import {analyticsService, authService, localPreferencesService, themeService} from '@/services';
import { WidgetHostContext } from '@/widgets/widget-host/widget-host-context';
import SingleWidgetHost from '@/components/dashboard/single-widget-host';
import useWidgetsFromLayouts from '@/components/dashboard/hooks/use-widgets-from-layouts';
import {
  DashboardAction,
  DashboardContentReducer,
  dashboardContentReducerWrapper,
  DashboardState,
} from '@/components/dashboard/store/dashboard-content-reducer';
import useDashboardPersistence from '@/components/dashboard/hooks/use-dashboard-persistence';
import useSubscription from '@/hooks/use-subscription';
import { WidgetNotification } from '@/widgets/notifications/widget-notification';
import { MessageServiceConstants } from '@/services/messaging/message-service-constants';
import useWidgetNotifications from '@/widgets/notifications/use-widget-notifications';
import { AppContext } from '@/model/app-context';
import { PreferenceConstants } from '@/api/preferences-api';
import DashboardUserMenu from '@/components/dashboard/dashboard-user-menu';
import { useAuth0 } from '@auth0/auth0-react';
import { useCookies } from 'react-cookie';
import DashboardCopyModal from './dashboard-copy-modal';
import { toast } from 'react-toastify';
import DashboardDrawer from '@/components/dashboard/dashboard-drawer';
import './dashboard.css';
import RemoveWhenHiddenDiv from '@/components/shared/remove-when-hidden-div';
import { getBreakpointFromElement } from './dashboard-breakpoints';
import {AnalyticsEvent} from '@/services/analytics/analytics-event';
import DashboardNotifications from './dashboard-notifications';
import Navbar from './navbar';

export default function Dashboard() {
  const [query, setQuery] = useSearchParams();
  const [initialized, setInitialized] = useState(false);
  const [showDeleteDashboardModal, setShowDeleteDashboardModal] = useState(false);
  const [showCreateDashboardModal, setShowCreateDashboardModal] = useState(false);
  const [showCopyDashboardModal, setShowCopyDashboardModal] = useState(false);
  const [copyDashboard, setCopyDashboard] = useState<MNIDashboard>();
  const [selectedDashboard, setSelectedDashboard] = useState<MNIDashboard>();
  const context = useContext<AppContext>(Context);

  const { getAccessTokenSilently } = useAuth0();
  const [_, setCookie] = useCookies();

  const { notificationSoundOn, setNotificationSoundOn } = useWidgetNotifications();
  const activeWidgets = useWidgetsFromLayouts(selectedDashboard);

  const [state, dispatch]: [s: DashboardState, d: (a: DashboardAction) => void] =
    useReducer<DashboardContentReducer>(dashboardContentReducerWrapper, {
      lastLayout: {} as Layouts,
      loading: true,
      editing: false,
      dirty: false,
      highlightedWidgets: {},
      dashboards: [],
    } as ReducerState<DashboardContentReducer>);

  useDashboardPersistence({ state, dispatch });

  useEffect(() => {
    setSelectedDashboard(state.dashboards.find(value => value.id === state.selectedDashboard));
  }, [state.selectedDashboard, state.dashboards]);

  useEffect(() => {
    if (state.loading) return;

    const widgetId = query.get('widget');

    if (widgetId) {
      setTimeout(() => {
        addWidget(widgetId);
      }, 1000);

      query.delete('widget');
      setQuery(query);
    }

    const share = query.get('share');

    if (share) {
      try {
        // Decode the share string
        const shareId = share;

        // Parse the JSON string back to an object
        shareApi.getShareValue<MNIDashboard>(shareId).then(
          dashboard => {
            if (!dashboard) {
              toast.error('Share link is not valid');
              return;
            }

            if (dashboard.name && dashboard.layouts) {
              setCopyDashboard(dashboard);
              setShowCopyDashboardModal(true);
            }
          },
          () => {
            toast.error('Share link expired!');
          },
        );
      } catch (e) {
        toast.error('Share link expired!');
      } finally {
        query.delete('share');
        setQuery(query);
      }
    }
  }, [query, state]);

  useEffect(() => {
    if (initialized) return;

    //refresh token (auth0) when initial load
    authService.loginWithAuth0(getAccessTokenSilently, setCookie);

    localPreferencesService.getLocalPreferences().then(value => {
      themeService.setThemeBasedOnUserPreferences(value);
    });

    preferencesApi.getPreferences<MyMNIPreferences>(PreferenceConstants.MY_MNI).then(val => {
      dispatch({
        type: 'dashboardsLoaded',
        dashboards: val?.dashboard || [],
      });
    });

    setInitialized(true);

    return () => {
      localPreferencesService.getLocalPreferences().then((value: any) => {
        themeService.setThemeBasedOnUserPreferences(value);
      });
    };
  }, []);

  useSubscription<WidgetNotification>(
    MessageServiceConstants.WIDGET_NOTIFICATION_ACKNOWLEDGED,
    message => {
      message.widgetIds.forEach(value => {
        dispatch({ type: 'highlightWidget', id: value });
      });

      setTimeout(() => {
        message.widgetIds.forEach(value => {
          dispatch({ type: 'unHighlightWidget', id: value });
        });
      }, 3_000);
    },
  );

  const addWidget = (widgetId: string) => {
    if (!state.selectedDashboard) {
      dispatch({
        type: 'createDashboard',
        name: 'My Dashboard',
        id: `${crypto.randomUUID()}-${new Date().getTime()}`,
      });
    }
    dispatch({
      type: 'addWidget',
      id: widgetId,
      save: true,
    });
  };

  const removeWidgetById = (widget: string) => {
    dispatch({
      type: 'removeWidget',
      id: widget,
    });
  };

  const createDashboard = (
    localDashboardName: string,
    layouts?: Layouts,
    preferences?: MNIDashboardWidgetPreferences,
  ) => {
    dispatch({
      type: 'createDashboard',
      name: localDashboardName,
      layouts: layouts,
      preferences: preferences,
      id: `${crypto.randomUUID()}-${new Date().getTime()}`,
    });
    dispatch({ type: 'editCurrentDashboard' });
    setShowCreateDashboardModal(false);
  };
  const deleteSelectedDashboard = () => {
    dispatch({
      type: 'deleteCurrentDashboard',
      id: state.selectedDashboard,
    });
  };

  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!state.currentBreakpoint && ref.current) {
      const currentBP = getBreakpointFromElement(ref.current);
      if (currentBP) {
        dispatch({ type: 'setBreakpoint', breakpoint: currentBP });
      }
    }
  }, [ref, state]);

  return (
    <>
      <Context.Provider value={{ showMenu: false, localPreferences: context.localPreferences }}>
        <RemoveWhenHiddenDiv className={'vh-100 d-block d-md-none'}>
          {
            <SingleWidgetHost
              headerControls={
                <>
                  <DashboardNotifications
                    notificationSoundOn={notificationSoundOn}
                    setNotificationSoundOn={setNotificationSoundOn}
                  />
                  <DashboardUserMenu className={' ms-1'} />
                </>
              }
            />
          }
        </RemoveWhenHiddenDiv>

        <WidgetHostContext.Provider
          value={{
            get activeWidgetIds(): string[] {
              return activeWidgets?.activeWidgets.map(value => value.id) || [];
            },
            removeWidgetById(id: string) {
              removeWidgetById(id);
            },
            addWidgetById(id: string) {
              addWidget(id);
              dispatch({ type: 'editCurrentDashboard' });
            },
            get hostId(): string {
              return 'dashboard';
            },
            get currentViewId(): string {
              return selectedDashboard?.id || 'default-view';
            },
            async getWidgetPreferences<T>(id: string): Promise<T> {
              let result: T = {} as T;

              if (selectedDashboard) {
                if (!selectedDashboard.widgetPreferences) selectedDashboard.widgetPreferences = {};

                if (selectedDashboard.widgetPreferences[id])
                  result = selectedDashboard.widgetPreferences[id];
              }

              return result;
            },
            async saveWidgetPreferences<T>(id: string, t: T): Promise<T> {
              console.log('Saving widget preferences');
              if (selectedDashboard) {
                if (!selectedDashboard.widgetPreferences) selectedDashboard.widgetPreferences = {};

                selectedDashboard.widgetPreferences[id] = t;

                dispatch({ type: 'saveCurrentDashboard' });
              }

              return t;
            },
          }}
        >
          <RemoveWhenHiddenDiv className="vh-100 dashboard-container d-none d-md-flex flex-column">
            <Navbar
              selectedDashboard={selectedDashboard}
              activeWidgets={activeWidgets}
              notificationSoundOn={notificationSoundOn}
              state={state}
              addWidget={addWidget}
              dispatch={dispatch}
              setNotificationSoundOn={setNotificationSoundOn}
              setShowCreateDashboardModal={setShowCreateDashboardModal}
              setShowDeleteDashboardModal={setShowDeleteDashboardModal}
            />
            <div className={'d-flex overflow-auto h-100 vw-100 '}>
              <div className={`h-100 overflow-auto flex-grow-1 flex-shrink-1 dashboardPanelArea`}>
                {selectedDashboard && activeWidgets && (
                  <DashboardContent
                    dispatch={dispatch}
                    layouts={selectedDashboard.layouts}
                    editMode={state.editing}
                    activeWidgets={activeWidgets}
                    highlightedWidgets={state.highlightedWidgets}
                  />
                )}

                {state.loading && (
                  <div className={'container-fluid gx-2'}>
                    <div className={'row gx-2'}>
                      <div className={'col-md-4'}>
                        <div
                          className={' rounded-2 mt-2 dashboardPlaceholder'}
                          style={{ height: '20em' }}
                        ></div>
                        <div
                          className={'rounded-2 mt-2 dashboardPlaceholder'}
                          style={{ height: '20em' }}
                        ></div>
                      </div>
                      <div className={'col-md-4'}>
                        <div
                          className={'rounded-2 mt-2 dashboardPlaceholder'}
                          style={{ height: '10em' }}
                        ></div>
                        <div
                          className={'rounded-2 mt-2 dashboardPlaceholder'}
                          style={{ height: '40em' }}
                        ></div>
                      </div>
                      <div className={'col-md-4'}>
                        <div
                          className={'rounded-2 mt-2 dashboardPlaceholder'}
                          style={{ height: '30em' }}
                        ></div>
                        <div
                          className={'rounded-2 mt-2 dashboardPlaceholder'}
                          style={{ height: '10em' }}
                        ></div>
                      </div>
                    </div>
                  </div>
                )}
                {!state.selectedDashboard && !state.loading && (
                  <div className={'container'}>
                    <div ref={ref} className={'row'}>
                      <div className={'col-12 text-center'}>
                        <div className={' m-5'}>
                          <h1 className={'mb-5'}>
                            Welcome to <span className={'fw-normal'}>My</span>MNI
                          </h1>
                          <div className={'row mb-5'}>
                            <div className={'col'}>
                              <h3>
                                <div className={'h1 mb-2'}>
                                  <FontAwesomeIcon icon={faSearch} />
                                </div>
                                Find
                              </h3>
                              <p>Find the information you need.</p>
                            </div>
                            <div className={'col'}>
                              <h3>
                                <h1>
                                  <FontAwesomeIcon icon={faRightLeft} />
                                </h1>
                                Organize
                              </h3>
                              <p>Create your custom experience.</p>
                            </div>
                            <div className={'col'}>
                              <h3>
                                <h1>
                                  <FontAwesomeIcon icon={faTowerCell} />
                                </h1>
                                Discover
                              </h3>
                              <p>Know what matters.</p>
                            </div>
                          </div>
                          <Button size={'lg'} onClick={() => setShowCreateDashboardModal(true)}>
                            <FontAwesomeIcon icon={faPlay} /> Get Started
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <DashboardDrawer />

              <DashboardCreateModal
                showCreateDashboardModal={showCreateDashboardModal}
                setShowCreateDashboardModal={setShowCreateDashboardModal}
                onCreate={createDashboard}
              />

              <DashboardCopyModal
                showCopyDashboardModal={showCopyDashboardModal}
                setShowCopyDashboardModal={b => {
                  setShowCopyDashboardModal(b);
                  if (!b) setCopyDashboard(undefined);
                }}
                dashboard={copyDashboard}
                amountOfDashboards={state.dashboards?.length ?? 0}
                onCopy={(dashboard: MNIDashboard) => {
                  createDashboard(dashboard.name, dashboard.layouts, dashboard.widgetPreferences);
                  setShowCopyDashboardModal(false);
                  setCopyDashboard(undefined);
                }}
              />

              <DashboardDeleteModal
                showDeleteDashboardModal={showDeleteDashboardModal}
                setShowDeleteDashboardModal={setShowDeleteDashboardModal}
                onConfirm={deleteSelectedDashboard}
              />
            </div>
          </RemoveWhenHiddenDiv>
        </WidgetHostContext.Provider>
      </Context.Provider>
    </>
  );
}
