import { NewsWidgetFactory } from '@/widgets/news-widget-factory';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { NewsWidget } from '@/widgets/news-widget';
import LocalWidgetRouter from '@/widgets/widget-router/local-widget-router';
import { convertWidgetToReactPath } from '@/widgets/widget-router/convert-widget-to-react-path';
import { WidgetContainerContext } from '@/widgets/widget-container/widget-container-context';

export function WidgetFactoryWrapper({ factory, url }: { factory: NewsWidgetFactory; url?:string }) {
  const { '*': spat, ...params } = useParams();
  const [widget, setWidget] = useState<NewsWidget>();
  const [concretePath, setConcretePath] = useState<string>();
  const [initialUrl, setInitialUrl] = useState<string>();
  const navigate = useNavigate();

  useEffect(() => {
    let template = factory.path;
    let path = url

    Object.keys(params).forEach(value => {
      template = template.replace(`:${value}`, params[value] || '');
    });
    setConcretePath(template);

    if(path && concretePath){
      Object.keys(params).forEach(value => {
        path = path?.replace(`:${value}`, params[value] || '');
      });
      path =  path?.replace(concretePath, '')
      setInitialUrl(path)
    }
  }, [params, url]);  

  useEffect(() => {
    if (concretePath) setWidget(factory.createInstance(concretePath));
  }, [concretePath]);

  if (widget) {
    document.title = widget.defaultTitle || '';

    return (
      <>
        <WidgetContainerContext.Provider
          value={{
            updateState<T>(state: Partial<T>): void {},
            getPreferences<T>(): T | undefined {
              return undefined;
            },
            setPreferences<T>(state: T): void {},
            setState<T>(state: T): void {},
            updatePreferences<T>(state: Partial<T>): void {},
            getState() {
              return undefined;
            },
            get widgetId(): string | undefined {
              return widget?.id;
            },
            close() {},
          }}
        >
          <LocalWidgetRouter
            widget={widget}
            path={initialUrl ?? spat}
            onNavigate={s => {
              console.log('Widget navigate:' + s);
              navigate(convertWidgetToReactPath(concretePath, s));
            }}
          />
        </WidgetContainerContext.Provider>
      </>
    );
  }

  return <></>;
}
