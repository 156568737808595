import {
  DashboardAction,
  DashboardState,
} from '@/components/dashboard/store/dashboard-content-reducer';
import { useEffect } from 'react';
import { preferencesApi } from '@/api';
import { compareDashboardSets } from '@/components/dashboard/dashboard-util';
import { PreferenceConstants } from '@/api/preferences-api';

export default function useDashboardPersistence({
  state,
  dispatch,
}: {
  state: DashboardState;
  dispatch: (p: DashboardAction) => void;
}) {
  useEffect(() => {
    console.warn('Dashboard State Change', state);

    if (state.dirty && !state.loading && (!state.editing || state.saveWhileEditing)) {
      console.info('Saving Dashboard', state.dashboards);
      if (!state.dashboards) return;

      if (compareDashboardSets(state.lastSavedDashboards, state.dashboards)) {
        console.info(
          'No Meaningful changes, returning',
          state.lastSavedDashboards,
          state.dashboards,
        );
        dispatch({ type: 'stopEditingCurrentDashboard' });
        return;
      }

      console.info(' Meaningful changes, saving', state.lastSavedDashboards, state.dashboards);

      preferencesApi
        .upsertPreferences(PreferenceConstants.MY_MNI, { dashboard: state.dashboards })
        .then(value => {
          dispatch({ type: 'recordSavedDashboards', dashboards: value.dashboard });
          dispatch({ type: 'stopEditingCurrentDashboard' });
        });
    }
  }, [state, state.dirty]);

  useEffect(() => {
    if (state.selectedDashboard) {
      console.log('Saving selected dashboard to local storage');
      localStorage.setItem('selectedDashboard', state.selectedDashboard);
    }
  }, [state.selectedDashboard]);
}
