import { CSSProperties } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarPlus } from '@fortawesome/free-solid-svg-icons';

import { NewsEvent } from '@/model/calendar/news-event';
import {analyticsService, calendarLinkService} from '@/services';
import {AnalyticsEvent} from '@/services/analytics/analytics-event';

export default function AddToCalendarButton({
  event,
  style,
}: {
  style?: CSSProperties | undefined;
  event: NewsEvent;
}) {

  const onLinkClick = (type:string)=>{
    analyticsService.recordAnalyticsEvent(AnalyticsEvent.HSC_EVENT_ADDED_TO_CALENDAR, {
      eventId:event.id,
      eventName:event.title,
      calendarType:type,
      date:event.date
    })
  }
  return (
    <Dropdown>
      <Dropdown.Toggle id="dropdown-basic" size="sm" style={style} variant={'link'}>
        <FontAwesomeIcon icon={faCalendarPlus} />
      </Dropdown.Toggle>
      <Dropdown.Menu className={'bg-quaternary border-warning'}>
        <Dropdown.Item href={calendarLinkService.createGoogleLink(event)} target={'_blank'}
                       onClick={() => onLinkClick("google")}>
          Add to Google Calendar
        </Dropdown.Item>
        <Dropdown.Item href={calendarLinkService.createOutlookLink(event)} target={'_blank'}
                       onClick={() => onLinkClick("outlook")}>
          Add to Outlook Live Calendar
        </Dropdown.Item>
        <Dropdown.Item href={calendarLinkService.createOffice365Link(event)} target={'_blank'}
                       onClick={() => onLinkClick("office365")}>
          Add to Office 365 Calendar
        </Dropdown.Item>
        <Dropdown.Item
          href={calendarLinkService.createICalDownloadLink(event)}
          download={'event.ics'}
          onClick={() => onLinkClick("ical")}
        >
          Download ICal File
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
