import { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import { selectApi } from '@/api';

export default function DataDownload() {
  const [startDate, setStartDate] = useState<Date>(dayjs().toDate());
  const [endDate, setEndDate] = useState<Date>(dayjs().add(1, 'week').toDate());
  const [downloading, setDownloading] = useState<boolean>(false);

  const datesValid = () => {
    return (
      startDate.getTime() < endDate.getTime() && dayjs(startDate).add(3, 'months').isAfter(endDate)
    );
  };

  const downloadData = () => {
    if (downloading) return;

    setDownloading(true);

    selectApi
      .getCalendar(startDate.getTime(), endDate.getTime())
      .then(value => {
        const sanitize = (input: any) => {
          if (input === null || input === undefined) input = '';

          if (input === '-Infinity') input = 'N/A';

          return `"${`${input}`.replaceAll('"', '\\"')}"`;
        };

        let csv = '';

        value.forEach(dataRelease => {
          dataRelease.dataSeriesEntries.forEach(series => {
            csv += `${sanitize(dataRelease.date)},\
                           ${sanitize(dataRelease.title)},\
                           ${sanitize(dataRelease.country)},\
                           ${sanitize(series.dataSeriesId)},\
                           ${sanitize(series.display)},\
                           ${sanitize(series.previous)},\
                           ${sanitize(series.forecast()?.value)},\
                           ${sanitize(series.actual)},\
                           ${sanitize(series.type)},\
                           ${sanitize(series.scale)}\n`;
          });
        });

        const blob = new Blob([csv], { type: 'text/csv' });

        // Creating an object for downloading url
        const url = URL.createObjectURL(blob);

        // Creating an anchor(a) tag of HTML
        const a = document.createElement('a');

        // Passing the blob downloading url
        a.setAttribute('href', url);

        // Setting the anchor tag attribute for downloading
        // and passing the download file name
        a.setAttribute(
          'download',
          `download.${dayjs(startDate).format('MM-DD-YYYY')}.${dayjs(endDate).format(
            'MM-DD-YYYY',
          )}.csv`,
        );

        // Performing a download with click
        a.click();
      })
      .finally(() => {
        setDownloading(false);
      });
  };

  return (
    <>
      <div className={'row mb-3'}>
        <div className={'col-sm mb-2'}>
          <label htmlFor="exampleInputEmail1" className="form-label">
            Start Date
          </label>
          <div>
            <ReactDatePicker
              className={'form-control w-100'}
              wrapperClassName={'w-100'}
              selected={startDate}
              onChange={date => setStartDate(dayjs(date).toDate())} //only when value has changed
            />
          </div>
        </div>
        <div className={'col-sm mb-2'}>
          <label htmlFor="exampleInputEmail1" className="form-label">
            End Date
          </label>

          <div>
            <ReactDatePicker
              className={'form-control'}
              wrapperClassName={'w-100'}
              selected={endDate}
              onChange={date => setEndDate(dayjs(date).toDate())} //only when value has changed
            />
          </div>
        </div>
      </div>
      {datesValid() || (
        <div className={'row mb-3'}>
          <div className={'col text-danger-light'}>
            Start must be before end date. Max range is three months.
          </div>
        </div>
      )}
      <div className={'row'}>
        <div className={'col'}>
          <button
            style={{ height: '40px', fontFamily: 'Inter' }}
            className={'btn btn-tertiary text-base-light'}
            disabled={!datesValid() || downloading}
            onClick={downloadData}
          >
            {downloading || 'Download data'}
            {downloading && (
              <>
                <Spinner size={'sm'} /> Downloading....
              </>
            )}
          </button>
        </div>
      </div>
    </>
  );
}
