import { Article } from '@/model/article';
import { CoverageItem, CoverageType } from '@/model/calendar/coverage-item';

export class EventCoverage {
  constructor(
    public readonly coverageItem: CoverageItem,
    public readonly article: Article,
  ) {}
}

export class CoverageMap {
  constructor(public coverage: { [key: string]: EventCoverage[] } = {}) {}

  addItem(eventCoverage: EventCoverage): CoverageMap {
    if (this.hasArticle(eventCoverage.article.uri)) return new CoverageMap(this.coverage);

    let name = eventCoverage.coverageItem.coverageType.name;
    if (!this.coverage[name]) {
      this.coverage[name] = [];
    }

    this.coverage[name].push(eventCoverage);

    return new CoverageMap(this.coverage);
  }

  articlesByType(name: string): Article[] {
    if (this.coverage[name])
      return this.coverage[name]
        .map(value => value.article)
        .sort(
          (a, b) => new Date(b.firstcreated).getTime() - new Date(a.firstcreated).getTime(),
        );

    return [];
  }

  hasArticle(id: string) {
    for (let coverageKey in this.coverage) {
      if (this.coverage[coverageKey].filter(value => value.article.uri === id).length > 0)
        return true;
    }

    return false;
  }

  coverageTypes(): CoverageType[] {
    const result: CoverageType[] = [];

    for (let coverageKey in this.coverage) {
      this.coverage[coverageKey]
        .map(value => value.coverageItem.coverageType)
        .forEach(value => {
          if (!result.find(existing => existing.name === value.name)) result.push(value);
        });
    }

    return result;
  }
}
