import ReactGA from 'react-ga4';
import {AnalyticsEvent} from '@/services/analytics/analytics-event';


const recordAnalyticsEvent = (eventName:AnalyticsEvent, additionalData:any) => {

  console.log("Event: ",eventName,additionalData);
  ReactGA.event(eventName, additionalData);
}

export default {
  recordAnalyticsEvent
}
