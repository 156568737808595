import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faGear, faRightFromBracket, faUser } from '@fortawesome/free-solid-svg-icons';
import { DropdownButton, NavDropdown } from 'react-bootstrap';
import { authService, installService } from '@/services';
import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import DashboardSettingsModal from '@/components/dashboard/dashboard-settings-modal';
import { useCookies } from 'react-cookie';

export default function DashboardUserMenu({ className }: { className?: string }) {
  const auth0 = useAuth0();
  const [_, _2, deleteCookie] = useCookies();
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const logout = () => {
    const source = authService.getCredentials()?.token.source;

    authService.logout().then(value => {
      if (source === 'auth0') {
        //remove shared cookies with website
        deleteCookie('permissions', {
          path: '/',
          domain: '.mnimarkets.com',
        });
        deleteCookie('refresh', {
          path: '/',
          domain: '.mnimarkets.com',
        });
        deleteCookie('token', {
          path: '/',
          domain: '.mnimarkets.com',
        });
        console.warn('Logging out of auth0');
        auth0.logout({
          logoutParams: {
            returnTo: window.location.origin,
            federated: true,
          },
        });
      } else {
        window.location.reload();
      }
    });
  };

  return (
    <>
      <DropdownButton
        className={className}
        variant={'secondary'}
        size={'sm'}
        title={
          <>
            <span className={' fw-bold'}>
              <FontAwesomeIcon icon={faUser} />
            </span>
          </>
        }
        id="basic-nav-dropdown"
      >
        <NavDropdown.Item onClick={event => setShowSettingsModal(true)}>
          <FontAwesomeIcon icon={faGear} /> Settings
        </NavDropdown.Item>

        {installService.canInstall() && (
          <NavDropdown.Item onClick={event => installService.install()}>
            <FontAwesomeIcon icon={faDownload} /> Install
          </NavDropdown.Item>
        )}
        <NavDropdown.Divider />
        <NavDropdown.Item onClick={logout}>
          <FontAwesomeIcon icon={faRightFromBracket} /> Logout
        </NavDropdown.Item>
      </DropdownButton>
      <DashboardSettingsModal
        showSettingsModal={showSettingsModal}
        setShowSettingsModal={setShowSettingsModal}
      />
    </>
  );
}
