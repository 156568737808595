import { RouteObject } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { NewsWidget } from '@/widgets/news-widget';
import { WidgetHost } from '@/widgets/widget-host';
import { NewsWidgetFactory, WidgetMenuEntry } from '@/widgets/news-widget-factory';
import Podcast from '@/components/podcasts';
import { faPodcast } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { routeMatches } from '@/widgets/widget-router/route-matches';
import { localPreferencesService } from '@/services';

const urlPath = '/podcasts/:podcastSlug';

enum SlugToId {
    'tech-analysis' = 'HLLC4546099276',
    'fed-speak' = 'HLLC8487778275'
}

enum SlugToTitle {
    'tech-analysis' = 'Tech Analysis',
    'fed-speak' = 'Fed Speak'
}

export class PodcastWidgetFactory implements NewsWidgetFactory {
    get path(): string {
        return urlPath;
    }

    get headerPanel(): string | JSX.Element {
        return (
            <>
                <FontAwesomeIcon icon={faPodcast} /> Podcasts
            </>
        );
    }

    matchesTemplate(s: string): boolean {
        return !!routeMatches(urlPath, s);
    }

    createInstance(s: string): NewsWidget {
        const match = routeMatches(urlPath, s);

        if (match) return new PodcastWidget(match.podcastSlug);

        throw new Error('invalid url');
    }

    getListableUrls(): WidgetMenuEntry[] {
        return [
            {
                url: '/podcasts/tech-analysis',
                name: 'Tech Analysis',
            },
            {
                url: '/podcasts/fed-speak',
                name: 'Fed Speak',
            },
        ];
    }
}

export class PodcastWidget implements NewsWidget {
    defaultTitle: string = 'Podcast';
    constructor(private podcast: string) { }

    async hasPermission(permissions: string[]) {
        return true;
    }

    get headerPanel(): JSX.Element {
        return (
            <>
                <FontAwesomeIcon icon={faPodcast} /> {SlugToTitle[this.podcast as keyof typeof SlugToTitle]}
            </>
        );
    }

    headerActions({ children }: { children?: React.ReactNode }): JSX.Element {
        return <>{children}</>;
    }

    get id(): string {
        return '/podcasts/' + this.podcast;
    }

    initialize(host: WidgetHost): void { }

    get shortTitle(): JSX.Element {
        return (
            <>
                <FontAwesomeIcon icon={faPodcast} /> {this.podcast}
            </>
        );
    }

    get routes(): RouteObject[] {
        return [
            {
                path: '/',
                element: <PodcastWrapper podcastId={SlugToId[this.podcast as keyof typeof SlugToId]} />,
            },
        ];
    }

    get defaultRoute(): string {
        return `/`;
    }
}

function PodcastWrapper({ podcastId }: { podcastId: string }) {
    const [light, setLight] = useState<boolean | undefined>();

    useEffect(() => {
        async function fetchPreferences() {
            const preferences = await localPreferencesService.getLocalPreferences();
            setLight(preferences.theme === 'light');
        }
        fetchPreferences();
    }, []);

    return <Podcast podcastId={podcastId} light={light} />;

}
