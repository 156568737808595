import React from 'react';

import { NewsWidget } from '@/widgets/news-widget';
import LocalWidgetRouter from '@/widgets/widget-router/local-widget-router';

export class WidgetDockAdapter {
  constructor(
    public readonly id: string,
    private newsWidget: NewsWidget,
  ) {}

  get content(): React.Component | JSX.Element {
    return (
      <>
        <div className={'p-1 h-100'}>
          {<LocalWidgetRouter widget={this.newsWidget} onNavigate={s => {}} />}
        </div>
      </>
    );
  }

  get title(): React.Component | JSX.Element {
    return this.newsWidget.headerPanel;
  }

  get shortTitle(): JSX.Element {
    return this.newsWidget.shortTitle;
  }

  get group() {
    return 'card custom';
  }

  get closable(): boolean {
    return true;
  }
}
