import { NewsWidget } from '@/widgets/news-widget';
import { WidgetHost } from '@/widgets/widget-host';
import { RouteObject } from 'react-router-dom';
import { sectionApi } from '@/api';

export interface BaseScrollerState {
  includedSections?: string[];
  title?: string;
}

export abstract class BaseScroller implements NewsWidget<BaseScrollerState> {
  defaultTitle: string = '';
  protected constructor(
    readonly id: string,
    protected section: string[],
    protected _initialState: BaseScrollerState,
    protected _shortTitle: string,
  ) {
    this.defaultTitle = _initialState.title ?? '';
    this._initialState.includedSections = [];
  }

  async hasPermission(permissions: string[]) {
    const section = await Promise.allSettled(
      this.section.map(value => sectionApi.getSectionByCode(value)),
    );

    return section.some(value => value.status === 'fulfilled' && value.value.flags.authorized);
  }

  initialize(host: WidgetHost) {}

  initialState(): BaseScrollerState {
    return this._initialState;
  }

  defaultPreferences(): {} {
    return {};
  }

  get shortTitle(): JSX.Element {
    return <>{this._shortTitle}</>;
  }

  abstract get defaultRoute(): string;

  abstract get headerPanel(): JSX.Element;

  abstract headerActions({ children }: { children?: React.ReactNode }): JSX.Element;

  abstract get routes(): RouteObject[];
}
