import { useContext } from 'react';
import { WidgetContainer } from '@/widgets/widget-container';
import { WidgetContainerContext } from '@/widgets/widget-container/widget-container-context';
import { WidgetHost } from '@/widgets/widget-host';
import { WidgetHostContext } from '@/widgets/widget-host/widget-host-context';

interface WidgetPreferencesService<T> {
  getWidgetPreferences(id?: string): Promise<T | null>;
  saveWidgetPreferences(p: T, id?: string): Promise<T>;
  preferencesKey: string;
}

export default function useWidgetPreferences<T>(): WidgetPreferencesService<T> {
  const { widgetId } = useContext<WidgetContainer>(WidgetContainerContext);
  const { hostId, currentViewId, getWidgetPreferences, saveWidgetPreferences } =
    useContext<WidgetHost>(WidgetHostContext);
  const preferencesKey = `widget-preferences-${hostId}-${currentViewId}-${widgetId}`;

  return {
    async getWidgetPreferences(id?: string): Promise<T | null> {
      return getWidgetPreferences<T>((id ?? widgetId) || '');
    },
    async saveWidgetPreferences(p: T, id?: string): Promise<T> {
      return saveWidgetPreferences<T>((id ?? widgetId) || '', p);
    },
    preferencesKey,
  };
}
