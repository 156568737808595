import { ToastContainer } from 'react-toastify';
import React from 'react';
import useSubscription from '@/hooks/use-subscription';
import { WidgetNotification } from '@/widgets/notifications/widget-notification';
import { MessageServiceConstants } from '@/services/messaging/message-service-constants';
import { toast } from 'react-toastify';
import useWidgetNotifications from '@/widgets/notifications/use-widget-notifications';
import './toasts-wrapper.css';

export default function ToastsWrapper() {
  const { acknowledgeNotification } = useWidgetNotifications();

  useSubscription<WidgetNotification>(
    MessageServiceConstants.WIDGET_NOTIFICATION_RECEIVED,
    notification => {
      toast.clearWaitingQueue();

      const toastId = toast.info(
        `${notification.notification.title}: ${notification.notification.description}`,
        {
          onClick: () => acknowledgeNotification(notification),
        },
      );

      // Manually dismiss after 4 seconds
      setTimeout(() => {
        toast.dismiss(toastId);
      }, 4000);
    },
  );

  return (
    <ToastContainer
      autoClose={4000} //4seconds
      hideProgressBar={true}
      limit={3}
      position={'bottom-right'}
      closeOnClick={true}
      pauseOnFocusLoss={false}
      stacked={true}
      theme={document.documentElement.getAttribute('data-bs-theme') === 'dark' ? 'dark' : 'light'}
    />
  );
}
