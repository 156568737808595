import { Button, Modal } from 'react-bootstrap';
import { MNIDashboard } from '@/model/preferences/myMNIPreferences';
import { useEffect } from 'react';

export default function DashboardCopyModal({
  showCopyDashboardModal,
  setShowCopyDashboardModal,
  dashboard,
  amountOfDashboards,
  onCopy,
}: {
  showCopyDashboardModal: boolean;
  setShowCopyDashboardModal: (b: boolean) => void;
  dashboard: MNIDashboard | undefined;
  amountOfDashboards: number;
  onCopy: (d: MNIDashboard) => void;
}) {
  useEffect(() => {
    if (showCopyDashboardModal && !dashboard) setShowCopyDashboardModal(false);
  }, [dashboard, showCopyDashboardModal]);

  return (
    <Modal
      show={showCopyDashboardModal}
      onHide={() => {
        setShowCopyDashboardModal(false);
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="p-3">
        {amountOfDashboards < 5 ? (
          <h4 className="text-center">
            Are you sure you want to copy the dashboard
            <br />
            <b style={{ fontSize: 20 }}>{dashboard?.name}</b>
            <br />
            into a new dashboard?
          </h4>
        ) : (
          <h4 className="text-center">
            You already have the maximum amount of dashboards, in order to copy this one, please
            remove one of yours before
          </h4>
        )}
        <Modal.Footer>
          <Button
            style={{ height: '40px', fontFamily: 'Inter' }}
            className="btn btn-warning"
            onClick={() => {
              setShowCopyDashboardModal(false);
            }}
          >
            Cancel
          </Button>

          {amountOfDashboards < 5 && (
            <Button
              style={{ height: '40px', fontFamily: 'Inter' }}
              className="btn btn-success"
              onClick={() => {
                if (dashboard) onCopy(dashboard);
              }}
            >
              Copy
            </Button>
          )}
        </Modal.Footer>
      </div>
    </Modal>
  );
}
