import { ChangeEvent } from 'react';

import { DataTopic } from '@/model/data/data-topic';
import Checkbox, { CheckboxState } from '@/components/shared/checkbox';
import { TopicFilter } from '@/components/data-calendar/topic-filter';

enum ParentState {
  NO_CHILDREN_SELECTED,
  SOME_CHILDREN_SELECT,
  ALL_CHILDREN_SELECTED,
}

function allChildren(topic: DataTopic): DataTopic[] {
  const result: DataTopic[] = [];

  topic.children &&
    topic.children.forEach(value => {
      result.push(value);

      allChildren(value).forEach(value1 => result.push(value1));
    });

  return result;
}

function childrenSelected(topic: DataTopic, selectedTopics: TopicFilter): ParentState {
  if (!topic.children || topic.children.length === 0) return ParentState.NO_CHILDREN_SELECTED;

  const children = allChildren(topic);
  const selectedChildren = children.filter(value => selectedTopics[value.qcode]);
  if (children.length === selectedChildren.length) return ParentState.ALL_CHILDREN_SELECTED;
  else if (selectedChildren.length > 0) return ParentState.SOME_CHILDREN_SELECT;
  else return ParentState.NO_CHILDREN_SELECTED;
}

export function TopicMenu({
  topics,
  selectedTopics,
  onSelectionChange,
}: {
  topics: DataTopic[];
  selectedTopics: TopicFilter;
  onSelectionChange: (s: TopicFilter) => void;
}) {
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    selectedTopics.allSelected = event.target.checked;

    onSelectionChange(new TopicFilter(selectedTopics));
  };

  return (
    <>
      <ul>
        <li className="form-check">
          <input
            type={'checkbox'}
            className={'form-check-input'}
            key={'check-'}
            onChange={event => {
              onChange(event);
            }}
            checked={selectedTopics.allSelected}
          />{' '}
          All
        </li>
      </ul>
      <TopicMenuNode
        selectedTopics={selectedTopics}
        topics={topics}
        onSelectionChange={onSelectionChange}
      ></TopicMenuNode>
    </>
  );
}

export function TopicMenuNode({
  topics,
  selectedTopics,
  onSelectionChange,
}: {
  topics: DataTopic[];
  selectedTopics: TopicFilter;
  onSelectionChange: (s: TopicFilter) => void;
}) {
  const onChildSelect = (s: TopicFilter, parent: DataTopic) => {
    if (childrenSelected(parent, s) === ParentState.ALL_CHILDREN_SELECTED) {
      s[parent.qcode] = true;
    } else s[parent.qcode] = false;

    onSelectionChange(new TopicFilter(s));
  };

  const onChange = (value: boolean, s: DataTopic) => {
    if (value) {
      selectedTopics[s.qcode] = true;
      allChildren(s).forEach(value => (selectedTopics[value.qcode] = true));
    } else {
      selectedTopics[s.qcode] = false;
      allChildren(s).forEach(value => (selectedTopics[value.qcode] = false));
    }

    onSelectionChange(new TopicFilter(selectedTopics));
  };

  return (
    <ul>
      {topics.map(value => {
        let state = CheckboxState.Unchecked;

        let childState = childrenSelected(value, selectedTopics);

        if (
          selectedTopics[value.qcode] ||
          selectedTopics.allSelected ||
          childState === ParentState.ALL_CHILDREN_SELECTED
        )
          state = CheckboxState.Checked;
        else if (childState === ParentState.SOME_CHILDREN_SELECT)
          state = CheckboxState.Indeterminate;

        return (
          <>
            <li>
              <div className="form-check">
                <Checkbox
                  onChange={a => onChange(a, value)}
                  value={state}
                  className={'form-check-input'}
                  disabled={selectedTopics.allSelected}
                />
                {value.name}
              </div>
            </li>

            {value.children && (
              <li>
                <TopicMenuNode
                  topics={value.children}
                  selectedTopics={selectedTopics}
                  onSelectionChange={s => onChildSelect(s, value)}
                />
              </li>
            )}
          </>
        );
      })}
    </ul>
  );
}
