import { MNIDashboard } from '@/model/preferences/myMNIPreferences';
import { Layout, Layouts } from 'react-grid-layout';
import { isEqual } from 'lodash';

export function compareLayout(l1: Layout, l2: Layout) {
  return l1.i == l2.i && l1.x == l2.x && l1.y == l2.y && l1.h == l2.h && l1.w == l2.w;
}

export function compareLayoutsArray(l1: Layout[], l2: Layout[]) {
  if (l1.length != l2.length) return false;

  for (var i = 0; i < l1.length; i++) {
    if (!compareLayout(l1[i], l2[i])) return false;
  }

  return true;
}

function sortLayout(a: Layout, b: Layout) {
  if (a.i > b.i) return -1;
  if (a.i < b.i) return 1;

  return 0;
}

export function compareLayouts(l1: Layouts, l2: Layouts) {
  const k1 = Object.keys(l1).sort();
  const k2 = Object.keys(l2).sort();

  if (!isEqual(k1, k2)) return false;

  for (var k of k1) {
    if (!compareLayoutsArray(l1[k].sort(sortLayout), l2[k].sort(sortLayout))) return false;
  }

  return true;
}

export function compareDashboards(d1: MNIDashboard, d2: MNIDashboard) {
  if (d1.id != d2.id) return false;

  if (d1.name != d2.name) return false;

  if (JSON.stringify(d1.widgetPreferences) != JSON.stringify(d2.widgetPreferences)) return false;

  return compareLayouts(d1.layouts, d2.layouts);
}

export function compareDashboardSets(d1?: MNIDashboard[], d2?: MNIDashboard[]) {
  if (!d1 || !d2) return false;

  if (d1.length != d2.length) return false;

  const k1 = d1.map(value => value.id).sort();
  const k2 = d2.map(value => value.id).sort();

  if (!isEqual(k1, k2)) return false;

  for (let k of k1) {
    if (
      !compareDashboards(
        d1.find(value => value.id == k) as MNIDashboard,
        d2.find(value => value.id == k) as MNIDashboard,
      )
    )
      return false;
  }

  return true;
}
