import { Article } from '@/model/article';
import { Page } from '@/model/page';
import { SearchResult } from '@/model/news/search-result';
import axios from 'axios';
import * as qs from 'qs';

let fixSummary = (article: Article) => {
  if (!article.body_xhtml) article.body_xhtml = '';

  let pdfMatch = /\[rebelmouse-document-pdf (\d+) .* original_filename="(.*)"]/.exec(
    article.body_xhtml,
  );

  if (pdfMatch) {
    article.pdfs = [];
    let doc = `${pdfMatch[2]}`.replace(/\+/g, '%20');
    article.pdfs.push(`https://roar-assets-auto.rbl.ms/documents/${pdfMatch[1]}/${doc}`);
  }

  article.body_xhtml = article.body_xhtml.replace(
    /\[rebelmouse-document-pdf (\d+) .* original_filename="(.*)"]/g,
    '',
  );
  article.body_xhtml = article.body_xhtml.replace(/\[rebelmouse-image .*]/g, '');

  if (article.genre.some(value => value.scheme == 'mnitype' && value.code == 'BULLET')) {
    article.person = [];
    article.byline = '';
  }

  return article;
};

let fixPage = (page: Page<Article>): Page<Article> => {
  page.content.forEach(fixSummary);

  return page;
};

async function getArticles(
  ids: string[],
  { teasers }: { teasers?: boolean | undefined } = {},
): Promise<Page<Article>> {
  const page = await axios.get<Page<Article>>(`/api/v1/news/articles?id=${ids}&teaser=${true}`);

  return fixPage(page.data);
}

async function getArticle(id: string): Promise<Article> {
  const article = await axios.get<Article>(`/api/v1/news/articles/${id}`);

  return fixSummary(article.data);
}

async function search(
  q: string,
  {
    sort = 'firstcreated',
    keywords,
    page,
  }: { sort?: 'firstcreated' | 'score'; keywords?: string[]; page: number },
): Promise<Page<SearchResult<Article>>> {
  const query = qs.stringify({
    sort,
    keywords,
    q,
    advancedSearch: true,
    page,
  });
  return (await axios.get(`/api/v1/news/search/articles?${query}`)).data;
}

//OK
async function getArticlesBySection(
  section: string | undefined | string[],
  {
    limit,
    page,
    excludeSubjects = [],
    excludeGenres = [],
    includeSubjects = [],
    includeGenres = [],
  }: {
    limit?: number;
    page?: number;
    excludeSubjects?: string[];
    excludeGenres?: string[];
    includeSubjects?: string[];
    includeGenres?: string[];
  },
): Promise<Page<Article>> {
  const query = qs.stringify(
    {
      size: limit,
      page: page,
      excludeSubjects: excludeSubjects,
      excludeGenres: excludeGenres,
      section: section,
      includeSubjects,
      includeGenres,
    },
    { arrayFormat: 'repeat' },
  );

  const result = await axios.get<Page<Article>>(`/api/v1/news/articles?${query}`);

  return fixPage(result.data);
}

async function getArticlesByIntersection({
  include,
  keywords,
  exclude,
  teasers,
}: {
  keywords?: string | string[];
  include?: string | string[];
  exclude?: string | string[];
  teasers?: boolean | undefined;
}): Promise<Page<Article>> {
  const page = await axios.get<Page<Article>>('/api/v1/news/articles');

  return fixPage(page.data);
}

async function getRelated(article: Article): Promise<Page<Article>> {
  //console.log(url)
  let subjects: string[] = [];

  article.subject.forEach(subject => {
    if (subject.scheme === 'mni-section') {
      subjects.push(`${subject.scheme}:${subject.code}`);
    }
  });

  return getArticlesByIntersection({ include: subjects });
}

export default {
  getRelated,
  getArticles,
  getArticle,
  getArticlesByIntersection,
  getArticlesBySection,
  search,
};
