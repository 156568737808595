export class PromiseQueue<T> {
  promise?: Promise<T>;

  enqueue(func: () => Promise<T>): Promise<T> {
    if (this.promise) return this.promise;

    this.promise = func();
    this.promise.finally(() => {
      delete this.promise;
    });
    return this.promise;
  }
}
