import { useLoaderData, useNavigate } from 'react-router-dom';

import ArticleView from '@/components/articles/article-view';
import { Article } from '@/model/article';
import useWidgetLoaderData from '@/widgets/widget-router/use-widget-loader-data';
import useWidgetNavigate from '@/widgets/widget-router/use-widget-navigate';

export default function ArticleViewPage({
  showBookmark = true,
  showBack = true,
  showPin = true,
}: {
  showBookmark?: boolean;
  showBack?: boolean;
  showPin?: boolean;
}) {
  const navigate = useWidgetNavigate();
  const article = useWidgetLoaderData();

  return (
    <>
      <ArticleView
        article={article as Article}
        onClose={() => navigate(-1)}
        showPin={showPin}
        showBack={showBack}
        showBookmark={showBookmark}
      />
    </>
  );
}
