import useWidgetNavigate from '@/widgets/widget-router/use-widget-navigate';
import { useEffect, useState } from 'react';
import { Calendar } from '@/model/calendar/calendar';
import { NewsEvent } from '@/model/calendar/news-event';
import { selectApi } from '@/api';
import Events from '@/components/calendars/events';

export default function SingleCalendarTitle({ calendarSlug }: { calendarSlug: string }) {
  const [calendar, setCalendar] = useState<Calendar>();

  useEffect(() => {
    selectApi.getCalendarBySlug(calendarSlug).then(setCalendar);
  }, [calendarSlug]);

  return <>{calendar?.name}</>;
}
