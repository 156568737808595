import { LocalStorageService } from '@/services/storage/local-storage-service';

export interface StorageService {
  saveValue(key: string, t: any): Promise<void>;

  getValue<T>(key: string): Promise<T>;

  removeValue(key: string): Promise<void>;
}

const storageService = new LocalStorageService();

export { storageService };
