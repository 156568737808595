import axios from 'axios';

import { Article } from '@/model/article';
import { Page } from '@/model/page';
import { Opinion } from '@/model/opinions/opinions';
import { OpinionMap } from '@/model/opinions/opinion-map';

const base = '/api/v1/news';

export type OpinionType = 'BOOKMARK' | 'LIKE';
async function saveOpinion(uri: string, opinion: OpinionType): Promise<Opinion | null> {
  return await axios.put(`${base}/opinions/${uri}/${opinion}`);
}

async function removeOpinion(uri: string, opinion: OpinionType): Promise<null> {
  return await axios.delete(`${base}/opinions/${uri}/${opinion}`);
}

async function getArticlesByOpinion(
  opinion: OpinionType,
  { page }: { page: number },
): Promise<Page<Article>> {
  return (await axios.get(`${base}/articles/by-opinion/${opinion}?page=${page}`)).data;
}

async function getOpinionsOfArticles(...uris: string[]): Promise<OpinionMap> {
  return (await axios.get(`${base}/my-opinions?${uris.map(s => `uri=${s}`).join('&')}`)).data;
}

export default {
  saveOpinion,
  removeOpinion,
  getArticlesByOpinion,
  getOpinionsOfArticles,
};
