import { Subscription } from '@/services/messaging/subscription';

export class ClientSubscription {
  constructor(
    public onMessage: (message: any, headers: any) => void,
    private subscription: Subscription,
  ) {
    subscription.clientSubscriptions.push(this);
  }

  unsubscribe() {
    this.subscription.clientSubscriptions = this.subscription.clientSubscriptions.filter(
      value => value !== this,
    );
  }
}
