import React, { useContext, useEffect, useState } from 'react';
import { GridLoader } from 'react-spinners';

import useTimer from '@/hooks/use-timer';
import { articleApi } from '@/api';
import { Article } from '@/model/article';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { WidgetContainer } from '@/widgets/widget-container';
import { WidgetContainerContext } from '@/widgets/widget-container/widget-container-context';
import { PinnedWidgetPreferences } from '@/widgets/widgets/pinned/pinned-series-widget';
import useWidgetNavigate from '@/widgets/widget-router/use-widget-navigate';
import PageControl from '@/components/shared/page-control';
import theme from '@/index.module.scss';

interface SeriesScrollerProps {
  articles?: Article[];
  includeGenres: string[];
  dark?: boolean;
  title?: JSX.Element;
  onArticlesChange: (articles?: Article[]) => void;
}

export default function SeriesScroller(props: React.PropsWithChildren<SeriesScrollerProps>) {
  const [articles, setArticles] = useState<Article[]>();
  const [offset, setOffset] = useState<number>(0);

  const widgetContainer = useContext<WidgetContainer>(WidgetContainerContext);
  const navigate = useWidgetNavigate();

  const fetchData = (offset: number, clearCurrent: boolean) => {
    if (clearCurrent) setArticles(undefined);

    articleApi
      .getArticlesBySection(undefined, {
        page: offset,
        limit: 15,
        includeGenres: props.includeGenres,
      })
      .then(
        value => {
          setArticles(value.content);
        },
      );
  };

  const setPage = (offset: number) => {
    setArticles(undefined);
    setOffset(offset);
    fetchData(offset, true);
  };

  useEffect(() => {
    props.onArticlesChange(articles);
  }, [articles]);

  useEffect(() => {
    fetchData(offset, true);
  }, [props.includeGenres]);

  useTimer(
    30000,
    () => {
      fetchData(offset, false);
    },
    false,
  );

  const bg = widgetContainer.getPreferences<PinnedWidgetPreferences>()?.color ?? 'note-yellow'

  return (
    <div className={`container-element h-100 ${bg}`}>
      <div className={'h-100 d-flex flex-column container-responsive-text'}>
        <PageControl
          offset={offset}
          onChange={setPage}
          dark={false}
          onClose={() => navigate('/')}
        />

        {!articles && (
          <div style={{ textAlign: 'center' }} className={'mt-5'}>
            <GridLoader color={theme.info} />
          </div>
        )}

        <div className={'flex-fill mt-3 overflow-auto '}>{props.children}</div>
      </div>
    </div>
  );
}
