import { useState } from 'react';

import useTimer from '@/hooks/use-timer';
import CountryFlag from '@/components/shared/country-flag';
import DateFormat from '@/components/shared/date-format';
import { readerService } from '@/services';
import { DataSeriesReading } from '@/services/reader/data-series-reading';

export default function ReaderIndicator() {
  let [dataSeriesReading, setDataSeriesReading] = useState<DataSeriesReading | undefined>(
    undefined,
  );

  useTimer(
    1000,
    () => {
      setDataSeriesReading(readerService.getDataSeriesReading());
    },
    false,
  );

  return (
    <>
      {dataSeriesReading && (
        <small className={' text-body'}>
          Reading <CountryFlag country={dataSeriesReading.country} height={12} />
          <span className={'font-monospace fw-bold'}> {dataSeriesReading.text} </span>
          @<DateFormat format={'M/D h:mm a'} date={dataSeriesReading?.date} />{' '}
        </small>
      )}
    </>
  );
}
