import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChessBoard,
  faPenToSquare,
  faPlus,
  faShareNodes,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import { MNIDashboard } from '@/model/preferences/myMNIPreferences';
import InfoTooltip from '../shared/info-tooltip';

export default function DashboardViewControls({
  onDelete,
  onEdit,
  selectedDashboard,
  onDashboardSelect,
  onDashboardShare,
  dashboards,
  onDashboardCreate,
}: {
  onDelete: () => void;
  onEdit: () => void;
  onDashboardCreate: () => void;
  onDashboardSelect: (d: MNIDashboard) => void;
  onDashboardShare: (d: MNIDashboard) => void;
  selectedDashboard?: MNIDashboard;
  dashboards: MNIDashboard[];
}) {
  return (
    <>
      <ButtonGroup>
        <DropdownButton
          as={ButtonGroup}
          className={''}
          size={'sm'}
          variant={'outline-secondary'}
          style={{ fontFamily: 'Inter' }}
          title={selectedDashboard?.name || '-none-'}
        >
          {dashboards.length > 1 ? (
            dashboards
              .filter(value => value.id !== selectedDashboard?.id)
              .map(value => (
                <Dropdown.Item onClick={event => onDashboardSelect(value)} key={value.id}>
                  <FontAwesomeIcon icon={faChessBoard} /> {value.name}
                </Dropdown.Item>
              ))
          ) : (
            <Dropdown.Item disabled={true}>No dashboards</Dropdown.Item>
          )}
          {(dashboards.length < 5 || selectedDashboard) && (
            <>
              <Dropdown.Divider />
              {dashboards.length < 5 && (
                <Dropdown.Item onClick={onDashboardCreate}>
                  <FontAwesomeIcon icon={faPlus} /> Create dashboard
                </Dropdown.Item>
              )}
              {selectedDashboard && (
                <div className="d-flex align-items-center">
                  <Dropdown.Item
                    onClick={_ => onDashboardShare(selectedDashboard)}
                    className={'d-flex justify-content-between'}
                  >
                    <span>
                      <FontAwesomeIcon icon={faShareNodes} /> Share dashboard
                    </span>
                    <span>
                      <InfoTooltip message="Share link will be valid for 7 days" />
                    </span>
                  </Dropdown.Item>
                </div>
              )}
            </>
          )}
          <>
            <Dropdown.Divider />
            <Dropdown.Item onClick={onDelete} className={'text-danger'}>
              <FontAwesomeIcon icon={faTrash} /> Delete current dashboard
            </Dropdown.Item>
          </>
        </DropdownButton>

        <button
          style={{ fontFamily: 'Inter' }}
          className={'btn btn-secondary me-2 btn-sm'}
          onClick={onEdit}
        >
          <FontAwesomeIcon icon={faPenToSquare} />
        </button>
      </ButtonGroup>
    </>
  );
}
