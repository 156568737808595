import { NewsWidget } from '@/widgets/news-widget';
import { useEffect, useState } from 'react';
import { authService } from '@/services';

export function useWidgetPermission(widget: NewsWidget): {
  authorized?: boolean;
  loadingPermission: boolean;
} {
  const [state, setState] = useState<{ authorized?: boolean; loadingPermission: boolean }>({
    loadingPermission: true,
  });

  useEffect(() => {
    const permissions = authService.getCredentials()?.body.permissions || [];

    widget.hasPermission(permissions).then(
      value => {
        setState({
          authorized: value,
          loadingPermission: false,
        });
      },
      reason => {
        setState({
          authorized: false,
          loadingPermission: false,
        });
      },
    );
  }, [widget]);

  return state;
}
