import { useContext, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
  faBookmark,
  faCalendar,
  faDownload,
  faEllipsisVertical,
  faGear,
  faMagnifyingGlass,
  faPersonWalkingArrowRight,
  faUpRightAndDownLeftFromCenter,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import RequirePermissions from '@/components/shared/require-permissions';
import { AppContext } from '@/model/app-context';
import { Context } from '@/App';
import { Credentials } from '@/model/auth/credentials';
import { Permissions } from '@/model/permissions';

import styles from '@/components/shared/menu/index.module.scss';
import { authService, installService } from '@/services';

export default function Menu({ padding = true }: { padding?: boolean }) {
  const [credentials, setCredentials] = useState<Credentials>();

  useEffect(() => {
    authService.onTokenAvailable().then(value => {
      setCredentials(authService.getCredentials);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logout = () => {
    authService.logout().then(() => {
      window.location.reload();
    });
  };

  const { showMenu } = useContext<AppContext>(Context);

  if (!showMenu) return <></>;

  return (
    <div className={'btn-group ' + (padding && 'ms-1')} role="group" aria-label="Basic example">
      <Dropdown>
        <Dropdown.Toggle
          type={'button'}
          variant="outline-secondary "
          id="dropdown-basic"
          size="sm"
          className={`${styles.settingsDropdown} btn-menu`}
        >
          <FontAwesomeIcon icon={faEllipsisVertical} />
        </Dropdown.Toggle>

        <Dropdown.Menu className={'border-warning'}>
          <div className={'dropdown-item disabled small'}>
            Logged in as <strong>{credentials?.body.sub}</strong>
          </div>
          <Dropdown.Divider className={'border-secondary'} />
          <Link to={'/'} className={'dropdown-item'}>
            <FontAwesomeIcon className={'text-secondary'} icon={faCalendar} /> Data Calendar
          </Link>
          <RequirePermissions allow={Permissions.BETA_ACCESS}>
            <Link to={'/calendar/hot-list'} className={'dropdown-item'}>
              <small>🔥</small> Hot Events
            </Link>
            <Link to={'/bullets/all-bullets'} className={'dropdown-item'}>
              <span className={`${styles.customBadge} font-monospace small`}>All</span> Bullets
            </Link>
            <Link to={'/bullets/fx-bullets'} className={'dropdown-item'}>
              <span className={`${styles.customBadge} font-monospace small`}>FX</span> Bullets
            </Link>
            <Link to={'/bullets/fi-bullets'} className={'dropdown-item'}>
              <span className={`${styles.customBadge} font-monospace small`}>FI</span> Bullets
            </Link>
            <Link to={'/bookmarks'} className={'dropdown-item'}>
              <FontAwesomeIcon className={'text-secondary'} icon={faBookmark} /> Bookmarks
            </Link>
            <Link to={'/search'} className={'dropdown-item'}>
              <FontAwesomeIcon className={'text-secondary'} icon={faMagnifyingGlass} /> Search
            </Link>
            <Dropdown.Divider className={'border-secondary'} />
            <Dropdown.Item href={'/dock'}>
              <FontAwesomeIcon className={'text-secondary'} icon={faUpRightAndDownLeftFromCenter} />{' '}
              Big Mode
            </Dropdown.Item>
          </RequirePermissions>
          <Dropdown.Divider className={'border-secondary'} />
          <Link to={'/settings'} className={'dropdown-item'}>
            <FontAwesomeIcon className={'text-secondary'} icon={faGear} />️ Settings
          </Link>

          <RequirePermissions allow={Permissions.BETA_ACCESS}>
            {installService.canInstall() && (
              <Dropdown.Item onClick={() => installService.install()}>
                <FontAwesomeIcon className={'text-secondary'} icon={faDownload} />️ Install
              </Dropdown.Item>
            )}
          </RequirePermissions>

          <Dropdown.Divider className={'border-secondary'} />
          <Dropdown.Item onClick={() => logout()} target={'_blank'}>
            <FontAwesomeIcon className={'text-secondary'} icon={faPersonWalkingArrowRight} /> Logout{' '}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
