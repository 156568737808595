import { forwardRef } from 'react';
import {
  faCalendar,
  faChevronLeft,
  faChevronRight,
  faCircleExclamation,
  faFilter,
} from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import ReactDatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Clock from '@/components/shared/clock';
import Menu from '@/components/shared/menu';

const dateFormat = 'YYYY-MM-DD';

export default function CalendarMenu({
  date,
  view,
  onNavigate,
  onToggleFilters,
  filtersActive,
}: {
  date: string;
  view: 'daily' | 'weekly';
  onNavigate: ({ date, view }: { date: string; view: 'daily' | 'weekly' }) => void;
  onToggleFilters: () => void;
  filtersActive?: boolean;
}) {
  const unit = (view: string): 'week' | 'day' => (view === 'weekly' ? 'week' : 'day');
  const next = (date: string) => {
    onNavigate({
      date: dayjs(date).tz().add(1, unit(view)).format(dateFormat),
      view: view,
    });
    //setDate(dayjs(date).tz().add(1, unit(view)).format(dateFormat));
  };

  const prev = (date: string) => {
    onNavigate({
      date: dayjs(date).tz().subtract(1, unit(view)).format(dateFormat),
      view: view,
    });
    //setDate(dayjs(date).tz().subtract(1, unit(view)).format(dateFormat));
  };

  const today = () => {
    onNavigate({
      date: 'today',
      view: view,
    });
    //setDate('today');
  };

  const daily = () => {
    onNavigate({
      date: date,
      view: 'daily',
    });
    //setView('daily');
  };

  const weekly = () => {
    onNavigate({
      date: date,
      view: 'weekly',
    });
    //setView('weekly');
  };

  return (
    <>
      <div className={'d-flex pt-1'}>
        <span className={'me-2 d-container-sm-block d-container-none'}>
          <img
            alt="logo"
            src={'/logo32.png'}
            height={'16'}
            width={'41'}
            className={' '}
            style={{ marginTop: '4px' }}
          />
        </span>
        <span className={'w-25'}>
          <select className={'form-select form-select-sm text-neutral-500 btn-menu'}>
            <option value={''}>All Data</option>
          </select>
        </span>
        <span>
          <button
            className={`btn btn-sm text-text-dark ms-1`}
            style={{ backgroundImage: 'none' }}
            onClick={() => onToggleFilters()}
            key={'filter-' + date}
          >
            <FontAwesomeIcon icon={faFilter} />
            {filtersActive && (
              <FontAwesomeIcon className={'text-warning-light ms-1'} icon={faCircleExclamation} />
            )}
          </button>
        </span>

        <span className={'m-auto small'}>
          <Clock />
          <div className={'d-container-block d-container-sm-none text-center'}>
            <img alt="logo" src={'/logo32.png'} height={'9'} />
          </div>
        </span>
        <span>
          <div className="btn-group " role="group" aria-label="Basic example">
            {(() => {
              const DatePickerButton = forwardRef<any, any>(({ onClick }, ref) => (
                <button
                  className={`btn btn-sm btn-outline-secondary text-secondary btn-menu date-picker`}
                  onClick={onClick}
                  ref={ref}
                >
                  <span className={'d-container-none d-container-md-inline'}>
                    {dayjs(date).tz().format('MM-DD')}
                  </span>
                  <span className={'d-container-inline d-container-md-none'}>
                    <FontAwesomeIcon icon={faCalendar} />
                  </span>
                </button>
              ));

              return (
                <ReactDatePicker
                  selected={dayjs(date).tz().toDate()}
                  onChange={date => {
                    onNavigate({
                      date: dayjs(date).tz().format(dateFormat),
                      view: view,
                    });
                  }}
                  todayButton={
                    <button
                      style={{ fontFamily: 'Inter' }}
                      className={'btn btn-sm btn-secondary text-secondary text-base-light'}
                    >
                      Today
                    </button>
                  }
                  customInput={<DatePickerButton />}
                />
              );
            })()}
            <button
              className={`btn btn-sm btn-outline-secondary text-secondary hide-xxs btn-menu`}
              onClick={() => prev(date)}
              key={'prev-' + date}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            <button
              style={{ fontFamily: 'Inter' }}
              className={`btn btn-sm btn-outline-secondary text-secondary hide-xxs btn-menu`}
              onClick={() => today()}
            >
              <span className={'d-container-none d-container-md-inline'}>Today</span>
              <span className={'d-container-inline d-container-md-none'}>T</span>
            </button>
            <button
              className={`btn btn-sm btn-outline-secondary text-secondary hide-xxs btn-menu`}
              onClick={() => next(date)}
              key={'next-' + date}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          </div>
        </span>
        <div className="btn-group ms-1" role="group" aria-label="Basic example">
          <span className={'d-container-none d-container-sm-inline'}>
            <button
              style={{ fontFamily: 'Inter' }}
              className={`btn btn-sm ${
                view === 'daily'
                  ? 'btn-secondary text-base-light'
                  : 'btn-outline-secondary text-secondary btn-menu'
              }`}
              onClick={() => daily()}
            >
              <span className={'d-container-none d-container-md-inline'}>Daily</span>
              <span className={'d-container-md-none'}>D</span>
            </button>
            <button
              style={{ fontFamily: 'Inter' }}
              className={`btn btn-sm ${
                view === 'weekly'
                  ? 'btn-secondary text-base-light'
                  : 'btn-outline-secondary text-secondary btn-menu'
              }`}
              onClick={() => weekly()}
            >
              <span className={'d-container-none d-container-md-inline'}>Weekly</span>
              <span className={'d-container-md-none'}>W</span>
            </button>
          </span>
          <span className={'d-container-inline d-container-sm-none hide-xxs'}>
            <button
              style={{ fontFamily: 'Inter' }}
              className={`btn btn-sm btn-outline-secondary text-secondary btn-menu `}
              onClick={() => (view === 'daily' ? weekly() : daily())}
            >
              <sup className={view === 'daily' ? 'text-secondary-light' : ''}>D</sup>/
              <sub className={view === 'weekly' ? 'text-secondary-light' : ''}>W</sub>
            </button>
          </span>
        </div>
        <span>
          <Menu />
        </span>
      </div>
    </>
  );
}
