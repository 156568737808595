import axios from 'axios';
import { UpgradeRequest } from '@/model/upgrades/upgrade-request';
import qs from 'qs';

/**
 * Return true if the upgrade request exists, otherwise false.
 * @param body
 */
async function getUpgradeStatus(body: UpgradeRequest): Promise<boolean> {
  const query = qs.stringify(body);

  try {
    const res = await axios.get(`/api/customer-relations/upgrade-request?${query}`);

    return res.status === 200;
  } catch (e) {
    return false;
  }
}

async function createUpgradeRequest(body: UpgradeRequest): Promise<boolean> {
  const res = await axios.post(`/api/customer-relations/upgrade-request`, body);

  return res.status === 200;
}

export default {
  getUpgradeStatus,
  createUpgradeRequest,
};
